import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const videoContentSchema = baseContentSchema.merge(
  z.object({
    video: z.any(),
    isModal: z.boolean().optional(),
  }),
)

export type VideoContentSchema = z.infer<typeof videoContentSchema>

export const videoConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'VIDEO',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 12,
  contentKey: 'videoContent',
  schema: videoContentSchema,
  name: t('video'),
  examples: ['https://www.youtube.com/watch?v=hyYCn_kAngI'],
  description: t('upload_videos_embed_'),
  searchTags: [
    'video',
    'streaming',
    'video',
    'youtube',
    'vimeo',
    'embed',
    'iframe',
    'html',
    'interactive',
    'embed the web',
    'grow and share',
    'Express yourself',
  ],
})
