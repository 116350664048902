import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const tikTokEmbedContentSchema = baseContentSchema.merge(
  z.object({
    websiteUrl: z.string().optional(),
    html: z.string().optional(),
  }),
)

export type TikTokEmbedContentSchema = z.infer<typeof tikTokEmbedContentSchema>

export const tikTokEmbedConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'TIKTOK_EMBED',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 16,
  contentKey: 'tiktokEmbedContent',
  schema: tikTokEmbedContentSchema,
  name: t('tiktok_embed'),
  examples: ['https://www.tiktok.com/@zachking/video/6749520869598481669'],
  description: t('embed_tiktok_videos_to_enrich_your_page_content'),
  searchTags: [
    'tiktok',
    'feed',
    'video',
    'short',
    'reel',
    'embed',
    'iframe',
    'html',
    'embed the web',
  ],
})
