import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

import { RootStore } from './root-store'

type ImageOption = 'avatar' | 'logo' | 'banner'

export class EmailSignatureStore {
  rootStore: RootStore
  name: string | null = null
  description: string | null = null
  email: string | null = null
  phoneNumber: string | null = null
  websiteUrl: string | null = null
  imageUrl: string | null = null
  signature: string | null = null
  signaturePreview: string | null = null
  selectedImageOption: ImageOption | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true })
    makePersistable(this, {
      name: 'fingertip-email-signature-storage',
      properties: [
        'name',
        'description',
        'email',
        'phoneNumber',
        'websiteUrl',
        'imageUrl',
        'selectedImageOption',
      ],
      storage: typeof window !== 'undefined' ? window.localStorage : undefined,
    })
    this.rootStore = rootStore
  }

  setName(name: string | null) {
    this.name = name
  }

  setDescription(description: string | null) {
    this.description = description
  }

  setEmail(email: string | null) {
    this.email = email
  }

  setPhoneNumber(phoneNumber: string | null) {
    this.phoneNumber = phoneNumber
  }

  setWebsiteUrl(websiteUrl: string | null) {
    this.websiteUrl = websiteUrl
  }

  setImageUrl(imageUrl: string | null) {
    this.imageUrl = imageUrl
  }

  setSignature(signature: string | null) {
    this.signature = signature
  }

  setSignaturePreview(signaturePreview: string | null) {
    this.signaturePreview = signaturePreview
  }

  setSelectedImageOption(option: ImageOption | null) {
    this.selectedImageOption = option
  }
}
