import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const spotifyContentSchema = baseContentSchema.merge(
  z.object({
    websiteUrl: z.string().optional(),
  }),
)

export type SpotifyContentSchema = z.infer<typeof spotifyContentSchema>

export const spotifyConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'SPOTIFY',
  minW: 3,
  minH: 4,
  maxW: 4,
  maxH: 12,
  contentKey: 'spotifyContent',
  schema: spotifyContentSchema,
  metaKey: 'spotifyMeta',
  name: t('spotify'),
  examples: ['https://open.spotify.com/track/44AyOl4qVkzS48vBsbNXaC'],
  description: t('embed_playlists_or_songs_from_spotify'),
  searchTags: [
    'spotify',
    'music',
    'audio',
    'track',
    'playlist',
    'album',
    'podcast',
    'audiobook',
    'embed',
    'iframe',
    'html',
    'interactive',
    'embed the web',
    'grow and share',
  ],
})
