import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const socialFeedContentSchema = baseContentSchema.merge(
  z.object({
    key: z.string().optional(),
    label: z.string().optional(),
    websiteUrl: z.string().optional(),
    title: z.string().optional(),
  }),
)

export type SocialFeedContentSchema = z.infer<typeof socialFeedContentSchema>

export const socialFeedConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'SOCIAL_FEED',
  minW: 3,
  minH: 7,
  maxW: 4,
  maxH: 12,
  contentKey: 'socialFeedContent',
  schema: socialFeedContentSchema,
  metaKey: 'socialFeedMeta',
  name: t('social_feed'),
  description: t('display_a_feed_of_yo'),
  searchTags: ['social', 'feed', 'sync', 'instagram', 'tiktok', 'youtube'],
  priorityTags: ['instagram', 'tiktok', 'youtube'],
})
