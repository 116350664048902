import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const subscribeContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    buttonText: z.string().optional(),
    collectPhoneNumber: z.boolean().optional().default(false),
    collectFullName: z.boolean().optional().default(false),
    hideConditions: z.boolean().optional().default(true),
    description: z.string().optional(),
  }),
)

export type SubscribeContentSchema = z.infer<typeof subscribeContentSchema>

export const subscribeConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'SUBSCRIBE',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 14,
  contentKey: 'subscribeContent',
  schema: subscribeContentSchema,
  name: t('newsletter'),
  description: t('collect_emails_from_your_contacts'),
  searchTags: [
    'members',
    'subscribe',
    'crm',
    'mailchimp',
    'marketing',
    'newsletter',
    'email',
    'phone',
    'klaviyo',
    'contact',
    'tools',
    'tell them more',
  ],
})
