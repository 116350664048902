import { UserProfile } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/user_profile_pb'
import { Group1Icon } from '@fingertip/icons'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Card } from '@/components/ui/card'
import { cn } from '@/lib/utils'
import { getInitials } from '@/lib/utils/string-utils'

type Props = {
  profile: UserProfile
  isSelected: boolean
  onSelect: () => void
}

export const BookerMemberCard = ({ profile, isSelected, onSelect }: Props) => {
  return (
    <Card
      onClick={onSelect}
      className={cn(
        'p-6 rounded-lg cursor-pointer transition-all duration-100 border-2',
        { 'border-primary': isSelected, 'border-transparent': !isSelected },
      )}
    >
      <div className="flex flex-col items-center gap-4">
        {profile.avatarUrl ? (
          <Avatar className="mr-2 size-20">
            {profile?.avatarUrl && (
              <AvatarImage src={profile.avatarUrl} alt={profile.name} />
            )}
            <AvatarFallback
              delayMs={profile?.avatarUrl ? 300 : 0}
              value={getInitials(profile.name)}
            />
          </Avatar>
        ) : (
          <div className="size-20 rounded-full bg-muted/50 border-muted border flex items-center justify-center">
            <Group1Icon className="size-5 text-muted-foreground" />
          </div>
        )}
        <div>
          <h3 className="font-medium">{profile.name}</h3>
        </div>
      </div>
    </Card>
  )
}
