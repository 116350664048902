'use client'

import { makeAutoObservable } from 'mobx'

import { RootStore } from './root-store'

export class WalletStore {
  rootStore: RootStore
  name: string = ''
  email: string = ''
  phone: string = ''
  themeId: string = 'orange'

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true })
    this.rootStore = rootStore
  }

  setName(name: string) {
    this.name = name
  }

  setEmail(email: string) {
    this.email = email
  }

  setPhone(phone: string) {
    this.phone = phone
  }

  setThemeId(themeId: string) {
    this.themeId = themeId
  }
}
