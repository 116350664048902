import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const educationContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    schools: z.array(
      z.object({
        schoolName: z.string().optional(),
        degreeName: z.string().optional(),
        media: z.any(),
        linkedinUrl: z.string().optional(),
        startMonth: z.string().optional(),
        startYear: z.string().optional(),
        endMonth: z.string().optional(),
        endYear: z.string().optional(),
      }),
    ),
  }),
)

export type EducationContentSchema = z.infer<typeof educationContentSchema>

export const educationConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'EDUCATION',
  minW: 2,
  minH: 5,
  maxW: 4,
  maxH: 12,
  contentKey: 'educationContent',
  schema: educationContentSchema,
  name: t('education'),
  description: t('showcase_your_educational_experience'),
  searchTags: [
    'education',
    'school',
    'university',
    'linkedin',
    'personal',
    'grow and share',
  ],
})
