import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const workAreaContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    positions: z.array(
      z.object({
        title: z.string().optional(),
        contractType: z.string().optional(),
        description: z.string().optional(),
        companyName: z.string().optional(),
        companyLocation: z.string().optional(),
        media: z.any(),
        linkedinUrl: z.string().optional(),
        linkedinId: z.string().optional(),
        startMonth: z.string().optional(),
        startYear: z.string().optional(),
        endMonth: z.string().optional(),
        endYear: z.string().optional(),
      }),
    ),
  }),
)

export type WorkAreaContentSchema = z.infer<typeof workAreaContentSchema>

export const workHistoryConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'WORK_HISTORY',
  minW: 2,
  minH: 5,
  maxW: 4,
  maxH: 12,
  contentKey: 'workHistoryContent',
  schema: workAreaContentSchema,
  name: t('work_experience'),
  description: t('share_your_previous_and_current_work_experience'),
  searchTags: [
    'work history',
    'experience',
    'linkedin',
    'jobs',
    'position',
    'timeline',
    'company',
    'career',
    'grow and share',
  ],
})
