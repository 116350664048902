import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const calendlyEmbedContentSchema = baseContentSchema.merge(
  z.object({
    websiteUrl: z.string().optional(),
    html: z.string().optional(),
  }),
)

export type CalendlyEmbedContentSchema = z.infer<
  typeof calendlyEmbedContentSchema
>

export const calendlyEmbedConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'CALENDLY_EMBED',
  minW: 2,
  minH: 4,
  maxW: 4,
  maxH: 16,
  contentKey: 'calendlyEmbedContent',
  schema: calendlyEmbedContentSchema,
  name: t('calendly_embed'),
  examples: ['https://calendly.com/acmesales'],
  description: t('embed_calendly_to_enrich_your_page_content'),
  searchTags: [
    'calendly',
    'booking',
    'appointment',
    'scheduling',
    'event',
    'meeting',
    'embed',
    'iframe',
    'html',
    'embed the web',
  ],
})
