import { makeAutoObservable } from 'mobx'

import { RootStore } from './root-store'

type InvoiceCheckoutStage = 'CHECKOUT' | 'PAYMENT' | 'CONFIRMED'

export class InvoiceCheckoutStore {
  rootStore: RootStore
  stage: InvoiceCheckoutStage | undefined = 'CHECKOUT'
  clientSecret: string | undefined
  paymentIntentId: string | undefined
  siteContactId: string | undefined
  receiptEmail: string | undefined

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true })
    this.rootStore = rootStore
  }

  setStage(stage: InvoiceCheckoutStage | undefined) {
    this.stage = stage
  }

  setClientSecret(clientSecret: string | undefined) {
    this.clientSecret = clientSecret
  }

  setPaymentIntentId(paymentIntentId: string | undefined) {
    this.paymentIntentId = paymentIntentId
  }

  setSiteContactId(siteContactId: string | undefined) {
    this.siteContactId = siteContactId
  }

  setReceiptEmail(receiptEmail: string | undefined) {
    this.receiptEmail = receiptEmail
  }

  reset() {
    this.stage = 'CHECKOUT'
    this.clientSecret = undefined
    this.paymentIntentId = undefined
    this.siteContactId = undefined
    this.receiptEmail = undefined
  }
}
