import { PaymentCollectionType } from '@fingertip/creator-proto/gen/fingertip/common/enum/v1/payment_collection_type_pb'
import { EventType } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/event_type_pb'
import { SiteAvailabilityByDayUserInfo } from '@fingertip/creator-proto/gen/fingertip/creator/nylas/v1/nylas_pb'
import { startOfMonth } from 'date-fns'
import { makeAutoObservable } from 'mobx'

import { getTimezone } from '@/components/blocks/calendar/utils'
import { ALLOW_ANY_PROFESSIONAL } from '@/components/booking/booker/stages/booker-stage-member-picker'
import { LocationTypeEnumType } from '@/components/booking/types'

import { getQueryParam } from '../utils/query-param'
import { RootStore } from './root-store'

export type BookingStage =
  | 'SERVICES'
  | 'MEMBER_PICKER'
  | 'DATE_PICKER'
  | 'AVAILABLE_SLOTS'
  | 'PAYMENT'
  | 'FORM'
  | 'SUCCESS'

type StoreValues = {
  selectedUserId?: string | null
  selectedDate?: Date | null
  selectedTimeslot?: Date | null
  bookingId?: string | null
  paymentCollectionType: PaymentCollectionType
  bookingItemsData?: {
    eventTypeId: string
    quantity: number
    eventType: EventType
  }[]
  memberOptionsData?: string[]
  customPrice?: number
}

type BookingStageLogic = {
  next: (storeValues: StoreValues) => boolean
  skip?: (storeValues: StoreValues) => boolean
}

type BookingLogic = Record<BookingStage, BookingStageLogic>

export const bookerProgression: BookingLogic = {
  SERVICES: { next: () => true },
  MEMBER_PICKER: {
    skip: ({ memberOptionsData }) => (memberOptionsData?.length || 0) === 1,
    next: ({ bookingItemsData, memberOptionsData }) =>
      (memberOptionsData?.length || 0) > 0 &&
      (bookingItemsData?.length || 0) > 0,
  },
  DATE_PICKER: {
    next: ({ selectedUserId }) => ALLOW_ANY_PROFESSIONAL || !!selectedUserId,
  },
  AVAILABLE_SLOTS: {
    next: ({ selectedDate }) => !!selectedDate,
  },
  FORM: { next: ({ selectedTimeslot }) => !!selectedTimeslot },
  PAYMENT: {
    skip: ({ paymentCollectionType, customPrice }) => {
      return (
        ![
          PaymentCollectionType.COLLECT_UPFRONT,
          PaymentCollectionType.CAPTURE_DETAILS,
        ].includes(paymentCollectionType) || customPrice !== 0
      )
    },
    next: ({ selectedTimeslot }) => !!selectedTimeslot,
  },
  SUCCESS: { next: ({ bookingId }) => !!bookingId },
}

export const bookingStages: BookingStage[] = [
  'SERVICES',
  'MEMBER_PICKER',
  'DATE_PICKER',
  'AVAILABLE_SLOTS',
  'FORM',
  'PAYMENT',
]

export type BookingLocation = {
  type: LocationTypeEnumType
  label: string
  value: string
}

export class BookerStore {
  rootStore: RootStore
  selectionToken: string | null = null
  customPrice: number | null = null
  customLength: number | null = null
  selectedDate: Date | null = null
  selectedTimeslot: Date | null = null
  selectedGroupClassId: string | null = null
  selectedUserId: string | null = null
  selectedUsers: SiteAvailabilityByDayUserInfo[] | null = null
  month: Date = startOfMonth(new Date())
  stage: BookingStage | null =
    (getQueryParam('stage')?.toUpperCase() as BookingStage) || null
  siteSlug: string | null = getQueryParam('siteSlug') || null
  paymentCollectionType: PaymentCollectionType =
    PaymentCollectionType.UNSPECIFIED
  bookingId: string | null = getQueryParam('bookingId') || null
  attendanceId: string | null = getQueryParam('attendanceId') || null
  eventTypeId: string | null = getQueryParam('eventTypeId') || null
  selectedLocation: BookingLocation | null = null
  timeZone: string = getTimezone()
  bookingItems: string = '[]'
  memberOptions: string = '[]'
  formResponses: string = '[]'
  groupWaitlisted: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true, deep: true })
    this.rootStore = rootStore
  }

  setSelectionToken(selectionToken: string | null) {
    this.selectionToken = selectionToken
  }

  setCustomPrice(customPrice: number | null) {
    this.customPrice = customPrice
  }

  setCustomLength(customLength: number | null) {
    this.customLength = customLength
  }

  setSelectedDate(date: Date | null) {
    this.selectedDate = date
  }

  setSelectedGroupClassId(groupClassId: string | null) {
    this.selectedGroupClassId = groupClassId
  }

  setSelectedTimeslot(timeslot: Date | null) {
    this.selectedTimeslot = timeslot
  }

  setSelectedUserId(userId: string | null) {
    this.selectedUserId = userId
  }

  setSelectedUsers(users: SiteAvailabilityByDayUserInfo[] | null) {
    this.selectedUsers = users
  }

  setMonth(month: Date) {
    this.month = month
    this.selectedTimeslot = null
  }

  setStage(stage: BookingStage | null) {
    this.stage = stage
  }

  setSiteSlug(siteSlug: string) {
    this.siteSlug = siteSlug
  }

  setPaymentCollectionType(paymentCollectionType: PaymentCollectionType) {
    this.paymentCollectionType = paymentCollectionType
  }

  setEventTypeId(eventTypeId: string | null) {
    this.eventTypeId = eventTypeId
  }

  setBookingId(bookingId: string | null) {
    this.bookingId = bookingId
  }

  setAttendanceId(attendanceId: string | null) {
    this.attendanceId = attendanceId
  }

  setSelectedLocation(selectedLocation: BookingLocation | null) {
    this.selectedLocation = selectedLocation
  }

  setTimeZone(timeZone: string) {
    this.timeZone = timeZone
  }

  setGroupWaitlisted(groupWaitlisted: boolean) {
    this.groupWaitlisted = groupWaitlisted
  }

  addFormResponseId(formResponseId: string) {
    const formResponseIds = this.formResponseIds
    formResponseIds.push(formResponseId)
    this.formResponses = JSON.stringify(formResponseIds)
  }

  setFormResponseIds(formResponseIds: string[]) {
    this.formResponses = JSON.stringify(formResponseIds)
  }

  clearFormResponseIds() {
    this.formResponses = '[]'
  }

  get formResponseIds(): string[] {
    return JSON.parse(this.formResponses)
  }

  addBookingItem(eventType: EventType, quantity: number = 1) {
    const bookingItems = this.bookingItemsData

    const bookingItemIndex = bookingItems.findIndex((item: any) => {
      return item.eventTypeId === eventType.id
    })

    if (bookingItemIndex >= 0) {
      bookingItems[bookingItemIndex].quantity += quantity
    } else {
      bookingItems.push({ eventTypeId: eventType.id, quantity, eventType })
    }

    this.bookingItems = JSON.stringify(bookingItems)
  }

  removeBookingItem(eventTypeId: string, quantity: number = 1) {
    const bookingItems = this.bookingItemsData

    const bookingItemIndex = bookingItems.findIndex(
      (item: any) => item.eventTypeId === eventTypeId,
    )
    if (bookingItemIndex >= 0) {
      bookingItems[bookingItemIndex].quantity -= quantity

      if (bookingItems[bookingItemIndex].quantity <= 0) {
        bookingItems.splice(bookingItemIndex, 1)
      }
    }

    this.bookingItems = JSON.stringify(bookingItems)
  }

  removeAllOfBookingItem(eventTypeId: string) {
    const bookingItems = this.bookingItemsData

    const bookingItemIndex = bookingItems.findIndex(
      (item: any) => item.eventTypeId === eventTypeId,
    )
    bookingItems.splice(bookingItemIndex, 1)

    this.bookingItems = JSON.stringify(bookingItems)
  }

  removeAllBookingItems() {
    const bookingItems = this.bookingItemsData

    bookingItems.splice(0, bookingItems.length)

    this.bookingItems = JSON.stringify(bookingItems)
  }

  get bookingItemsData(): {
    eventTypeId: string
    quantity: number
    eventType: EventType
  }[] {
    const parsed = JSON.parse(this.bookingItems)

    return parsed.map((item: any) => {
      return {
        eventTypeId: item.eventTypeId,
        quantity: item.quantity,
        eventType: EventType.fromJson(item.eventType),
      }
    })
  }

  setMemberOptions(memberOptions: string[]) {
    this.memberOptions = JSON.stringify(memberOptions)
  }

  get memberOptionsData(): string[] {
    return JSON.parse(this.memberOptions)
  }

  get isCustomBooking(): boolean {
    return !!this.selectionToken
  }

  resetData() {
    this.stage = 'SERVICES'
    this.selectedUserId = null
    this.selectedDate = null
    this.selectedGroupClassId = null
    this.selectedTimeslot = null
    this.selectedUsers = null
    this.paymentCollectionType = PaymentCollectionType.UNSPECIFIED
    this.bookingId = null
    this.bookingItems = '[]'
    this.memberOptions = '[]'
    this.formResponses = '[]'
    this.groupWaitlisted = false
  }
}
