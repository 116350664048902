export const DRAGGABLE_EDGE_CLODE_THRESHOLD = 20

export const DEFAULT_WORKSPACE_SIDEBAR_WIDTH = 276
export const MIN_WORKSPACE_SIDEBAR_WIDTH = 150
export const MAX_WORKSPACE_SIDEBAR_WIDTH = 600

export const DEFAULT_CUSTOMER_SIDEBAR_WIDTH = 276
export const MIN_CUSTOMER_SIDEBAR_WIDTH = 150
export const MAX_CUSTOMER_SIDEBAR_WIDTH = 600

export const DEFAULT_SITE_SIDEBAR_WIDTH = 420
export const MIN_SITE_SIDEBAR_WIDTH = 380
export const MAX_SITE_SIDEBAR_WIDTH = 600
export const SITE_SIDEBAR_NAV_WIDTH = 64
export const CLOSE_SITE_SIDEBAR_WIDTH = 64
