import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const iframeContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    html: z.string().optional(),
    isModal: z.boolean().optional(),
  }),
)

export type IframeContentSchema = z.infer<typeof iframeContentSchema>

export const iframeConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'IFRAME',
  minW: 1,
  minH: 2,
  maxW: 4,
  maxH: 24,
  contentKey: 'iframeContent',
  schema: iframeContentSchema,
  name: t('html_embed'),
  description: t('embed_external_content_like_videos_maps_or_web_pages'),
  searchTags: ['embed', 'iframe', 'html', 'embed the web'],
})
