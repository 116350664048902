import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const websiteContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    description: z.string().optional(),
    buttonText: z.string().optional(),
    websiteUrls: z
      .array(
        z.object({
          websiteUrl: z.string().optional(),
          title: z.string().optional(),
          openInNewTab: z.boolean().optional(),
        }),
      )
      .optional(),
    media: z.any(),
  }),
)

export type WebsiteContentSchema = z.infer<typeof websiteContentSchema>

export const websiteConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'WEBSITE',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 12,
  contentKey: 'websiteContent',
  schema: websiteContentSchema,
  name: t('website_link'),
  description: t('add_a_custom_website_link_to_go_to_another_page'),
  searchTags: ['custom', 'url', 'website', 'links', 'Help them find you'],
})
