import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const qrContentSchema = baseContentSchema.merge(
  z.object({
    data: z.string().optional(),
    title: z.string().optional(),
  }),
)

export type QrContentSchema = z.infer<typeof qrContentSchema>

export const qrConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'QR',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 12,
  contentKey: 'qrContent',
  schema: qrContentSchema,
  name: t('qr_code'),
  description: t('add_a_qr_code_to_your_page'),
  searchTags: [
    'qr',
    'code',
    'scan',
    'mobile',
    'url',
    'link',
    'interactive',
    'Help them find you',
  ],
})
