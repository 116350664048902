import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const teamContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string(),
    teams: z.array(
      z.object({
        name: z.string().optional(),
        jobTitle: z.string().optional(),
        bio: z.string().optional(),
        media: z.any(),
        socials: z.array(
          z.object({
            key: z.string().optional(),
            websiteUrl: z.string().optional(),
          }),
        ),
      }),
    ),
  }),
)

export type TeamContentSchema = z.infer<typeof teamContentSchema>

export const teamConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'TEAM',
  minW: 2,
  minH: 4,
  maxW: 4,
  maxH: 12,
  contentKey: 'teamContent',
  schema: teamContentSchema,
  name: t('team'),
  description: t('showcase_your_team'),
  searchTags: [
    'team',
    'about',
    'Help them find you',
    'company',
    'careers',
    'tell them more',
  ],
})
