'use client'
import { createContext } from 'react'

import { BookingCheckoutStore } from '@/lib/stores/booking-checkout-store'
import { ConfirmAttendanceStore } from '@/lib/stores/confirm-attendance-store'
import { ConfirmBookingStore } from '@/lib/stores/confirm-booking-store'
import { FormEditorStore } from '@/lib/stores/form-editor-store'

import { BookerStore } from './booker-store'
import { CheckoutStore } from './checkout-store'
import { CustomerSidebarStore } from './customer-sidebar-store'
import { EmailSignatureStore } from './email-signature-store'
import { InvoiceCheckoutStore } from './invoice-checkout-store'
import { LocalStore } from './local-store'
import { MessageStore } from './message-store'
import { OnboardingStore } from './onboarding-store'
import { PageEditorStore } from './page-editor-store'
import { PublicStore } from './public-store'
import { SiteSidebarStore } from './site-sidebar-store'
import { TempStore } from './temp-store'
import { WalletStore } from './wallet-store'
import { WorkspaceSidebarStore } from './workspace-sidebar-store'

export class RootStore {
  private static instance: RootStore | null = null

  bookerStore!: BookerStore
  checkoutStore!: CheckoutStore
  emailSignatureStore!: EmailSignatureStore
  invoiceCheckoutStore!: InvoiceCheckoutStore
  bookingCheckoutStore!: BookingCheckoutStore
  confirmBookingStore!: ConfirmBookingStore
  confirmAttendanceStore!: ConfirmAttendanceStore
  localStore!: LocalStore
  workspaceSidebarStore!: WorkspaceSidebarStore
  customerSidebarStore!: CustomerSidebarStore
  siteSidebarStore!: SiteSidebarStore
  onboardingStore!: OnboardingStore
  pageEditorStore!: PageEditorStore
  tempStore!: TempStore
  walletStore!: WalletStore
  publicStore!: PublicStore
  messageStore!: MessageStore
  formEditorStore!: FormEditorStore

  public static getInstance(): RootStore {
    if (!RootStore.instance) {
      RootStore.instance = new RootStore()
    }
    return RootStore.instance
  }

  private constructor() {
    if (RootStore.instance) {
      return RootStore.instance
    }

    this.bookerStore = new BookerStore(this)
    this.checkoutStore = new CheckoutStore(this)
    this.emailSignatureStore = new EmailSignatureStore(this)
    this.invoiceCheckoutStore = new InvoiceCheckoutStore(this)
    this.bookingCheckoutStore = new BookingCheckoutStore(this)
    this.confirmBookingStore = new ConfirmBookingStore(this)
    this.confirmAttendanceStore = new ConfirmAttendanceStore(this)
    this.localStore = new LocalStore(this)
    this.workspaceSidebarStore = new WorkspaceSidebarStore(this)
    this.customerSidebarStore = new CustomerSidebarStore(this)
    this.siteSidebarStore = new SiteSidebarStore(this)
    this.onboardingStore = new OnboardingStore(this)
    this.pageEditorStore = new PageEditorStore(this)
    this.tempStore = new TempStore(this)
    this.walletStore = new WalletStore(this)
    this.publicStore = new PublicStore(this)
    this.messageStore = new MessageStore(this)
    this.formEditorStore = new FormEditorStore(this)
  }
}
const rootStoreInstance = RootStore.getInstance()

export const rootStore = rootStoreInstance
export const RootStoreContext = createContext<RootStore>(rootStoreInstance)
