import { PartialMessage, Timestamp } from '@bufbuild/protobuf'
import { MutationAction } from '@fingertip/creator-proto/gen/fingertip/common/enum/v1/mutation_action_pb'
import { Block } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_pb'
import { MessageTheme } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/message_theme_pb'
import { Page } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/page_pb'
import { PageTheme } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/page_theme_pb'
import { PageThemeSchema } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/page_theme_schema_pb'
import type { Site } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/site_pb'
import {
  Mutation,
  PageUpdateContent,
} from '@fingertip/creator-proto/gen/fingertip/creator/realtime/v1/realtime_pb'
import { Layout, LayoutItem } from '@fingertip/react-grid-layout'
import lodashIsEqual from 'lodash/isEqual'
import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { toast } from 'sonner'
import { v4 } from 'uuid'

import { blockConfigSwitch } from '@/components/blocks/shared/config-switch'
import {
  BlockConfig,
  BlockKind,
  BlockMetaType,
  BlockType,
} from '@/components/blocks/shared/types'
import {
  findBlockYPositionAboveOrBelow,
  getBlockScrollTop,
  getSm,
  scrollBlock,
} from '@/components/blocks/shared/utils'
import { getLayoutFromBlock } from '@/components/page-editor/utils/get-layout-from-block'

import { transformBlockContent } from '../utils/transform-blocks'
import { RootStore } from './root-store'

export type PageEditorDialog =
  | 'ADMIN'
  | 'EDIT_THEME'
  | 'EDIT_BLOCK'
  | 'ADD_BLOCK'
  | 'ADD_BLOCK_SIZE'
  | 'SHARE'
  | 'EDIT_HEADER'
  | 'EDIT_FOOTER'
  | 'EDIT_PAGE'
  | 'PUBLISH'
  | 'EDIT_MESSAGE_THEME'
  | 'EDIT_FORM_SETTINGS'
  | 'EDIT_FORM_FIELD'
  | 'ADD_FORM_FIELD'

export type PageEditorMode = 'EDIT' | 'PREVIEW'

export type LinkSocialsPage =
  | 'LINK_SOCIALS_INSTAGRAM'
  | 'LINK_SOCIALS_TWITTER'
  | 'LINK_SOCIALS_FB_PROFILE'
  | 'LINK_SOCIALS_FB_PAGE'
  | 'LINK_SOCIALS_LINKEDIN'
  | 'LINK_SOCIALS_TIKTOK'
  | 'LINK_SOCIALS_YOUTUBE'
  | 'LINK_SOCIALS_THREADS'
  | 'LINK_SOCIALS_BLUESKY'

export type EmailProvidersPage =
  | 'EMAIL_PROVIDERS_GMAIL'
  | 'EMAIL_PROVIDERS_OUTLOOK'
  | 'EMAIL_PROVIDERS_ICLOUD'

export type ShareSiteDialog =
  | 'INVITE'
  | 'SHARE_SITE'
  | 'QR_CODE'
  | 'POST_TO_SOCIALS'
  | 'LINK_TO_SOCIALS'
  | 'BUSINESS_CARD'
  | 'EMAIL_SIGNATURE'
  | 'EMAIL_PROVIDERS'
  | 'SHARE_WITH_CONTACTS'
  | 'VIEW_SITE'
  | 'ADD_TO_HOMESCREEN'
  | 'COPY_TEMPLATE'
  | LinkSocialsPage
  | EmailProvidersPage

type HistoryData =
  | { kind: 'BLOCKS'; value: BlockType[] }
  | { kind: 'PAGE'; value: PartialMessage<Page> | undefined }
  | { kind: 'PAGE_THEME'; value: PartialMessage<PageTheme> | undefined }

type StateHistory = {
  past: HistoryData[]
  future: HistoryData[]
}

type DndBlock = {
  id: string
  config: BlockConfig
  block: PartialMessage<Block>
  w: number
  h: number
}

type ScrollToBlock = {
  id: string
  position: 'TOP' | 'BOTTOM' | 'HALF' | 'DOUBLE'
}

type ResizeBlock = { id: string; w: number; h: number }

export type ThemeDialog =
  | 'FONT'
  | 'COLOR'
  | 'CUSTOM_COLOR'
  | 'BACKGROUND'
  | 'BLOCK'
  | 'THEME_PREVIEW'
  | 'ROUNDNESS'
  | 'HEADER'

export class PageEditorStore {
  rootStore: RootStore
  blocks: BlockType[] = []
  layouts: LayoutItem[] = []
  blockMetas: BlockMetaType[] = []
  history: StateHistory = { past: [], future: [] }
  canUndo: boolean = false
  canRedo: boolean = false
  hoveredBlockId: string | null | undefined = null
  selectedBlockId: string | null | undefined = null
  pageEditorDialog: PageEditorDialog | null = null
  resizeBlock: ResizeBlock | null = null
  dragBlockId: string | null = null
  currentBlockKind: BlockKind | null = null
  isSaving: boolean = false
  pageEditorMode: PageEditorMode = 'EDIT'
  shareSiteDialog: ShareSiteDialog | null = null
  transition: boolean = false
  scrollToBlock: ScrollToBlock | undefined | null = null
  formOverride: any | null = null
  mutations: PartialMessage<Mutation>[] = []
  blockSearch: string = ''
  dndBlock: DndBlock | null = null
  currentPage: PartialMessage<Page> | undefined = undefined
  currentPageTheme: PartialMessage<PageTheme> | undefined = undefined
  currentMessageTheme: PartialMessage<MessageTheme> | undefined = undefined
  currentSite: PartialMessage<Site> | undefined = undefined
  themeDialog: ThemeDialog | null = null
  mutationId: number = 0
  clientId: string = v4()
  scaleFactor: number = 1

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true })
    makePersistable(this, {
      name: 'fingertip-page-editor-storage',
      properties: ['pageEditorDialog'],
      storage: typeof window !== 'undefined' ? window.localStorage : undefined,
    })
    this.rootStore = rootStore
  }

  setBlocks(blocks: BlockType[]) {
    this.blocks = blocks
  }

  setLayouts(layouts: LayoutItem[]) {
    this.layouts = layouts
  }

  setBlockMetas(blockMetas: BlockMetaType[]) {
    this.blockMetas = blockMetas
  }

  upsertBlockMeta(newBlockMeta: BlockMetaType) {
    const newBlockMetas = [...this.blockMetas]
    const index = newBlockMetas.findIndex(
      (blockMeta) => blockMeta.id === newBlockMeta.id,
    )

    if (index > -1) {
      // If the blockMeta exists, update it
      newBlockMetas[index] = newBlockMeta
    } else {
      // If the blockMeta does not exist, add it to the array
      newBlockMetas.push(newBlockMeta)
    }

    this.blockMetas = newBlockMetas
  }

  setHistory(past: HistoryData) {
    if (lodashIsEqual(this.history.past[0], past)) {
      return
    }

    this.history = {
      // Add the new state only if it's different
      past: [past, ...this.history.past.slice(0, 49)],
      future: [],
    }

    this.canUndo = true
    this.canRedo = false
  }

  undo({ t }: { t: any }) {
    const [previous, ...past] = this.history.past
    if (!previous) {
      return
    }

    const currentState: HistoryData = (() => {
      switch (previous.kind) {
        case 'BLOCKS':
          return { kind: 'BLOCKS', value: [...this.blocks] }
        case 'PAGE':
          return { kind: 'PAGE', value: this.currentPage }
        case 'PAGE_THEME':
          return { kind: 'PAGE_THEME', value: this.currentPageTheme }
      }
    })()

    switch (previous.kind) {
      case 'BLOCKS':
        this.updateAllBlocks({ newBlocks: previous.value, t })
        break
      case 'PAGE':
        this.updatePage({
          content: {
            ...previous.value,
            bannerMedia: { media: previous.value?.bannerMedia },
            logoMedia: { media: previous.value?.logoMedia },
          },
        })
        break
      case 'PAGE_THEME':
        this.upsertPageTheme(previous.value?.content)
        break
    }

    this.history = {
      past,
      future: [currentState, ...this.history.future],
    }

    this.canUndo = past.length > 0
    this.canRedo = true
    this.setHoveredBlockId(null)
    this.setSelectedBlockId(null)
  }

  redo({ t }: { t: any }) {
    const [next, ...future] = this.history.future
    if (!next) {
      return
    }

    const currentState: HistoryData = (() => {
      switch (next.kind) {
        case 'BLOCKS':
          return { kind: 'BLOCKS', value: [...this.blocks] }
        case 'PAGE':
          return { kind: 'PAGE', value: this.currentPage }
        case 'PAGE_THEME':
          return { kind: 'PAGE_THEME', value: this.currentPageTheme }
      }
    })()

    switch (next.kind) {
      case 'BLOCKS':
        this.updateAllBlocks({ newBlocks: next.value, t })
        break
      case 'PAGE':
        this.updatePage({
          content: {
            ...next.value,
            bannerMedia: { media: next.value?.bannerMedia },
            logoMedia: { media: next.value?.logoMedia },
          },
        })
        break
      case 'PAGE_THEME':
        this.upsertPageTheme(next.value?.content)
        break
    }

    this.history = {
      past: [currentState, ...this.history.past],
      future,
    }

    this.canUndo = true
    this.canRedo = future.length > 0
    this.setHoveredBlockId(null)
    this.setSelectedBlockId(null)
  }

  updateBlock({
    updatedBlock,
    t,
    skipHistory,
  }: {
    updatedBlock: BlockType
    t: any
    skipHistory?: boolean
  }) {
    if (!skipHistory) {
      this.setHistory({ kind: 'BLOCKS', value: this.blocks })
    }

    const blocks = this.blocks.map((block) =>
      updatedBlock.id === block.id ? updatedBlock : block,
    )

    const layouts = this.layouts.map((layout) =>
      updatedBlock.id === layout.i
        ? getLayoutFromBlock({ block: updatedBlock, t })
        : layout,
    )

    const mutation: PartialMessage<Mutation> = {
      action: MutationAction.BLOCK_UPSERT,
      clientId: this.clientId,
      mutationId: this.mutationId,
      payload: {
        case: 'mutationBlockUpsert',
        value: updatedBlock,
      },
      sentAt: Timestamp.fromDate(new Date()),
    }

    this.setLayouts(layouts)
    this.setBlocks(blocks)
    this.isSaving = true
    this.mutations = [...this.mutations, mutation]
  }

  updateBlockLayout({ updatedBlock, t }: { updatedBlock: BlockType; t: any }) {
    const blocks = this.blocks.map((block) =>
      updatedBlock.id === block.id ? updatedBlock : block,
    )

    const layouts = this.layouts.map((layout) =>
      updatedBlock.id === layout.i
        ? getLayoutFromBlock({ block: updatedBlock, t })
        : layout,
    )

    this.setLayouts(layouts)
    this.setBlocks(blocks)
    this.isSaving = true
  }

  createBlock({
    createdBlock,
    updatedLayouts,
    type,
    t,
  }: {
    createdBlock: BlockType
    updatedLayouts?: Layout
    type: 'PLACEHOLDER' | 'DUPLICATE'
    t: any
  }) {
    this.setHistory({ kind: 'BLOCKS', value: this.blocks })

    const sentAt = Timestamp.fromDate(new Date())
    const newMutations: PartialMessage<Mutation>[] = []
    let newBlocks = []

    if (updatedLayouts) {
      for (const block of this.blocks) {
        // Find the matching layout in updatedLayout (assuming there's an identifier to match on)
        const updated = updatedLayouts.find(
          (layoutItem) => layoutItem.i === block.id,
        )

        let newBlock = block

        if (updated) {
          const existingSm = block.content?.content?.value?.sm

          const newSm = {
            ...existingSm,
            x: updated?.x || 0,
            y: updated?.y || 0,
          }

          newBlock = {
            ...block,
            content: {
              content: {
                ...(block.content?.content as any),
                value: {
                  version: 1,
                  ...block.content?.content?.value,
                  sm: newSm,
                },
              },
            },
          }

          if (!lodashIsEqual(existingSm, newSm)) {
            newMutations.push({
              action: MutationAction.BLOCK_REPLACE,
              clientId: this.clientId,
              mutationId: this.mutationId,
              payload: {
                case: 'mutationBlockReplace',
                value: {
                  id: newBlock.id,
                  path: '/sm',
                  value: JSON.stringify(newSm),
                },
              },
              sentAt,
            })
          }
        }

        newBlocks.push(newBlock)
      }
    } else {
      newBlocks = this.blocks
    }

    const newCreatedBlock = transformBlockContent({
      block: createdBlock,
      page: this.currentPage,
      type,
    })

    newBlocks.push(newCreatedBlock)

    newMutations.push({
      action: MutationAction.BLOCK_UPSERT,
      clientId: this.clientId,
      mutationId: this.mutationId,
      payload: {
        case: 'mutationBlockUpsert',
        value: newCreatedBlock,
      },
      sentAt,
    })

    const layouts = newBlocks.map((block) => getLayoutFromBlock({ block, t }))

    this.setBlocks(newBlocks)
    this.setLayouts(layouts)
    this.scrollToBlock = newCreatedBlock?.id
      ? { id: newCreatedBlock.id, position: 'TOP' }
      : null
    this.isSaving = true
    this.mutations = [...this.mutations, ...newMutations]
    this.setHoveredBlockId(newCreatedBlock.id)
    this.setSelectedBlockId(newCreatedBlock.id)
  }

  deleteBlock({
    block,
    t,
    isDesktop,
  }: {
    block: BlockType
    t: any
    isDesktop: boolean
  }) {
    const blockId = block?.id || ''
    this.setHistory({ kind: 'BLOCKS', value: this.blocks })
    this.layouts = this.layouts.filter((layout) => layout.i !== blockId)
    this.blocks = this.blocks.filter((block) => block.id !== blockId)
    this.blockMetas = this.blockMetas.filter(
      (blockMeta) => blockMeta.id !== blockId,
    )
    this.isSaving = true
    this.mutations = [
      ...this.mutations,
      {
        action: MutationAction.BLOCK_DELETE,
        clientId: this.clientId,
        mutationId: this.mutationId,
        sentAt: Timestamp.fromDate(new Date()),
        payload: {
          case: 'mutationBlockDelete',
          value: {
            id: blockId,
          },
        },
      },
    ]
    this.setHoveredBlockId(null)
    this.setSelectedBlockId(null)

    if (
      isDesktop &&
      ['EDIT_BLOCK', 'ADD_BLOCK'].includes(this?.pageEditorDialog || '')
    ) {
      this.setPageEditorDialog('ADD_BLOCK')
    } else {
      this.setPageEditorDialog(null)
    }

    toast(`Block deleted`, {
      action: {
        label: 'Undo',
        onClick: () => this.undo({ t }),
      },
    })
  }

  layoutChange({ layouts, t }: { layouts: Layout; t: any }) {
    const newBlocks: BlockType[] = []
    const newMutations: PartialMessage<Mutation>[] = []
    const sentAt = Timestamp.fromDate(new Date())

    layouts.forEach((layoutItem) => {
      // Retrieve the existing block from your store if needed
      const existingBlock = this.blocks.find(
        (block) => block.id === layoutItem.i,
      )

      if (!existingBlock || !existingBlock.content?.content?.case) {
        return
      }

      const existingSm = existingBlock.content?.content?.value?.sm

      const newSm = {
        ...existingSm,
        x: layoutItem.x,
        y: layoutItem.y,
        w: layoutItem.w,
        h: layoutItem.h,
      }

      const updatedBlock: BlockType = {
        ...existingBlock,
        content: {
          ...existingBlock.content,
          content: {
            ...existingBlock.content?.content,
            value: {
              ...existingBlock.content?.content?.value,
              sm: newSm,
            },
          },
        },
      }

      if (!lodashIsEqual(existingSm, newSm)) {
        newMutations.push({
          action: MutationAction.BLOCK_REPLACE,
          clientId: this.clientId,
          mutationId: this.mutationId,
          payload: {
            case: 'mutationBlockReplace',
            value: {
              id: updatedBlock.id,
              path: '/sm',
              value: JSON.stringify(newSm),
            },
          },
          sentAt,
        })
      }

      newBlocks.push(updatedBlock)
    })

    const newLayouts = newBlocks.map((block) =>
      getLayoutFromBlock({ block, t }),
    )

    this.setBlocks(newBlocks)
    this.setLayouts(newLayouts)
    this.mutations = [...this.mutations, ...newMutations]
  }

  updateAllBlocks({ newBlocks, t }: { newBlocks: BlockType[]; t: any }) {
    const newLayouts: LayoutItem[] = []
    const newMutations: PartialMessage<Mutation>[] = []
    const sentAt = Timestamp.fromDate(new Date())

    newBlocks.forEach((blockItem) => {
      newMutations.push({
        action: MutationAction.BLOCK_UPSERT,
        clientId: this.clientId,
        mutationId: this.mutationId,
        sentAt,
        payload: {
          case: 'mutationBlockUpsert',
          value: blockItem,
        },
      })

      newLayouts.push(getLayoutFromBlock({ block: blockItem, t }))
    })

    this.blocks.forEach((oldBlockItem) => {
      if (
        !newBlocks.some((newBlockItem) => newBlockItem.id === oldBlockItem.id)
      ) {
        newMutations.push({
          action: MutationAction.BLOCK_DELETE,
          clientId: this.clientId,
          mutationId: this.mutationId,
          sentAt,
          payload: {
            case: 'mutationBlockDelete',
            value: {
              id: oldBlockItem.id,
            },
          },
        })
      }
    })

    this.setBlocks(newBlocks)
    this.setLayouts(newLayouts)
    this.mutations = newMutations
    this.isSaving = true
  }

  quickAddBlock({
    block,
    isDialog,
    invalidateBlockMeta,
    t,
  }: {
    block: PartialMessage<Block>
    isDialog: boolean | undefined
    invalidateBlockMeta: (data: any) => void
    t: any
  }) {
    const content = block?.content?.content?.value as any

    const sm = getSm({
      currentBlock: {
        w: content?.sm?.w,
        h: content?.sm?.h,
      },
      layouts: this.layouts,
    })

    const newContent = {
      content: {
        ...(block?.content?.content as any),
        value: {
          version: 1,
          ...block?.content?.content?.value,
          sm,
        },
      },
    }

    const createdBlock = {
      ...block,
      id: v4(),
      content: newContent,
    }

    this.createBlock({
      createdBlock,
      updatedLayouts: this.layouts,
      type: 'PLACEHOLDER',
      t,
    })

    if (!isDialog) {
      this.setPageEditorDialog('EDIT_BLOCK')
    } else {
      this.setPageEditorDialog(null)
    }

    invalidateBlockMeta({
      blockId: block.id,
      kind: block.kind as BlockKind,
      content: newContent,
    })

    this.clearBlockSidebar()
  }

  clearBlockSidebar() {
    this.setBlockSearch('')
    this.setCurrentBlockKind(null)
  }

  duplicateBlock({
    block,
    invalidateBlockMeta,
    t,
  }: {
    block: BlockType
    invalidateBlockMeta: (data: any) => void
    t: any
  }) {
    const config = blockConfigSwitch({ kind: block.kind as BlockKind, t })
    const caseKey = config?.contentKey || ''
    const id = v4()

    const content: any = block?.content?.content?.value

    if (config?.kind === 'POLL') {
      content['pollId'] = undefined
    }

    const sm = getSm({
      selectedBlockId: block.id,
      layouts: this.layouts,
    })

    const newContent = {
      content: {
        case: caseKey as any,
        value: {
          ...content,
          blockId: id,
          sm,
        },
      },
    }

    this.createBlock({
      createdBlock: {
        id,
        kind: block.kind,
        name: block.name,
        content: newContent,
      },
      type: 'DUPLICATE',
      t,
    })

    invalidateBlockMeta({
      blockId: id,
      kind: block.kind as BlockKind,
      content: newContent,
    })
  }

  moveBlock({
    block,
    layout,
    direction,
    t,
  }: {
    block: BlockType
    layout: LayoutItem
    direction: 'UP' | 'DOWN'
    t: any
  }) {
    const config = blockConfigSwitch({ kind: block.kind as BlockKind, t })
    const caseKey = config?.contentKey || ''

    const blockId = block?.id || ''
    this.setDragBlockId(blockId)

    const oldTop = getBlockScrollTop({ blockId })

    const y = findBlockYPositionAboveOrBelow({
      layout,
      layouts: this.layouts,
      direction,
    })

    this.updateBlockLayout({
      updatedBlock: {
        ...block,
        content: {
          content: {
            case: caseKey as any,
            value: {
              ...block?.content?.content?.value,
              sm: {
                ...block?.content?.content?.value?.sm,
                y,
              },
            },
          },
        },
      },
      t,
    })

    setTimeout(() => {
      scrollBlock({ oldTop, blockId })
    }, 400)

    setTimeout(() => {
      this.setDragBlockId(null)
    }, 600)
  }

  setHoveredBlockId(hoveredBlockId?: string | null) {
    this.hoveredBlockId = hoveredBlockId
  }

  setSelectedBlockId(selectedBlockId?: string | null) {
    this.selectedBlockId = selectedBlockId
  }

  setPageEditorDialog(pageEditorDialog: PageEditorDialog | null) {
    this.pageEditorDialog = pageEditorDialog
    this.rootStore.siteSidebarStore.setDesktopIsOpen(true)
  }

  setResizeBlock(resizeBlock: ResizeBlock | null) {
    this.resizeBlock = resizeBlock
  }

  setDragBlockId(dragBlockId: string | null) {
    this.dragBlockId = dragBlockId
  }

  setCurrentBlockKind(currentBlockKind: BlockKind | null) {
    this.currentBlockKind = currentBlockKind
  }

  setIsSaving(isSaving: boolean) {
    this.isSaving = isSaving
  }

  setPageEditorMode(pageEditorMode: PageEditorMode) {
    this.pageEditorMode = pageEditorMode
  }

  setShareSiteDialog(shareSiteDialog: ShareSiteDialog | null) {
    this.shareSiteDialog = shareSiteDialog
  }

  setTransition(transition: boolean) {
    this.transition = transition
  }

  setScrollToBlock(scrollToBlock: ScrollToBlock | null) {
    this.scrollToBlock = scrollToBlock
  }

  setFormOverride(formOverride: any) {
    this.formOverride = formOverride
  }

  setMutations(mutations: Mutation[]) {
    this.mutations = mutations
  }

  setBlockSearch(blockSearch: string) {
    this.blockSearch = blockSearch
  }

  setDndBlock(dndBlock: DndBlock | null) {
    this.dndBlock = dndBlock
  }

  setCurrentPage(currentPage: PartialMessage<Page> | undefined) {
    this.currentPage = currentPage
  }

  setCurrentSite(currentSite: PartialMessage<Site> | undefined) {
    this.currentSite = currentSite
  }

  setCurrentPageTheme(currentPageTheme: PartialMessage<PageTheme> | undefined) {
    this.currentPageTheme = currentPageTheme
  }

  setThemeDialog(themeDialog: ThemeDialog | null) {
    this.themeDialog = themeDialog
  }

  setCurrentMessageTheme(
    currentMessageTheme: PartialMessage<MessageTheme> | undefined,
  ) {
    this.currentMessageTheme = currentMessageTheme
  }

  themeDialogBack() {
    const currentPageTheme = this.themeDialog

    switch (currentPageTheme) {
      case 'CUSTOM_COLOR':
        this.themeDialog = 'COLOR'
        break

      case null:
        this.themeDialog = null
        this.pageEditorDialog = null
        break

      default:
        this.themeDialog = null
        break
    }
  }

  updatePage({
    content,
    skipHistory,
  }: {
    content: PartialMessage<PageUpdateContent>
    skipHistory?: boolean
  }) {
    if (!skipHistory) {
      this.setHistory({ kind: 'PAGE', value: this.currentPage })
    }

    const newMutations: PartialMessage<Mutation>[] = []
    const sentAt = Timestamp.fromDate(new Date())

    newMutations.push({
      action: MutationAction.PAGE_UPDATE,
      clientId: this.clientId,
      mutationId: this.mutationId,
      sentAt,
      payload: {
        case: 'mutationPageUpdate',
        value: {
          content: {
            socialIcons: this.currentPage?.socialIcons,
            ...content,
          },
        },
      },
    })

    this.setCurrentPage({
      ...this.currentPage,
      ...content,
      logoMedia: content?.logoMedia
        ? content.logoMedia.media
        : this.currentPage?.logoMedia,
      bannerMedia: content?.bannerMedia
        ? content.bannerMedia.media
        : this.currentPage?.bannerMedia,
      validation: content?.validation,
    })
    this.mutations = newMutations
    this.isSaving = true
  }

  upsertPageTheme(content: PartialMessage<PageThemeSchema> | undefined) {
    this.setHistory({ kind: 'PAGE_THEME', value: this.currentPageTheme })

    const newMutations: PartialMessage<Mutation>[] = []
    const sentAt = Timestamp.fromDate(new Date())
    const id = this.currentPageTheme?.id || v4()

    const next = {
      ...this.currentPageTheme,
      id,
      content: {
        ...this.currentPageTheme?.content,
        ...content,
      },
    }

    newMutations.push({
      action: MutationAction.PAGE_THEME_UPSERT,
      clientId: this.clientId,
      mutationId: this.mutationId,
      sentAt,
      payload: {
        case: 'mutationPageThemeUpsert',
        value: {
          id,
          content: next?.content,
        },
      },
    })

    this.setCurrentPageTheme(next)
    this.mutations = newMutations
    this.isSaving = true
  }

  replacePageTheme({ key, value }: { key: string; value: any }) {
    this.setHistory({ kind: 'PAGE_THEME', value: this.currentPageTheme })

    const newMutations: PartialMessage<Mutation>[] = []
    const sentAt = Timestamp.fromDate(new Date())
    const id = this.currentPageTheme?.id

    const next = {
      ...this.currentPageTheme,
      content: {
        ...this.currentPageTheme?.content,
        [key]: value,
      },
    }

    newMutations.push({
      action: MutationAction.PAGE_THEME_REPLACE,
      clientId: this.clientId,
      mutationId: this.mutationId,
      sentAt,
      payload: {
        case: 'mutationPageThemeReplace',
        value: {
          id,
          path: `/${key}`,
          value: JSON.stringify({ value }),
        },
      },
    })

    this.setCurrentPageTheme(next)
    this.mutations = newMutations
    this.isSaving = true
  }

  setScaleFactor(scaleFactor: number) {
    this.scaleFactor = scaleFactor
  }
}
