import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const figmaEmbedContentSchema = baseContentSchema.merge(
  z.object({
    websiteUrl: z.string().optional(),
    html: z.string().optional(),
  }),
)

export type FigmaEmbedContentSchema = z.infer<typeof figmaEmbedContentSchema>

export const figmaEmbedConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'FIGMA_EMBED',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 16,
  contentKey: 'figmaEmbedContent',
  schema: figmaEmbedContentSchema,
  name: t('figma_embed'),
  examples: [
    'https://www.figma.com/file/zCl6dPZyG0DWoIOvetwBMG/Figma-Basics?node-id=0%3A286',
  ],
  description: t('embed_figma_designs_to_enrich_your_page_content'),
  searchTags: [
    'figma',
    'design',
    'ui',
    'embed',
    'iframe',
    'html',
    'sketch',
    'adobe',
    'embed the web',
  ],
})
