import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const pollContentSchema = baseContentSchema.merge(
  z.object({
    pollId: z.string().optional(),
    title: z.string().optional(),
  }),
)

export type PollContentSchema = z.infer<typeof pollContentSchema>

export const pollConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'POLL',
  minW: 2,
  minH: 4,
  maxW: 4,
  maxH: 12,
  contentKey: 'pollContent',
  schema: pollContentSchema,
  metaKey: 'pollMeta',
  name: t('poll'),
  description: t(
    'engage_your_list_with_interactive_polls_to_gather_opinions_or_feedback',
  ),
  searchTags: ['poll', 'quiz', 'questions', 'interactive', 'Express yourself'],
})
