import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const websiteEmbedContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    websiteUrl: z.string().optional(),
    isModal: z.boolean().optional(),
  }),
)

export type WebsiteEmbedContentSchema = z.infer<
  typeof websiteEmbedContentSchema
>

export const websiteEmbedConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'WEBSITE_EMBED',
  minW: 1,
  minH: 2,
  maxW: 4,
  maxH: 24,
  contentKey: 'websiteEmbedContent',
  schema: websiteEmbedContentSchema,
  name: t('website_embed'),
  description: t('embed_a_website_by_pasting_a_link'),
  searchTags: ['embed', 'iframe', 'html', 'embed the web'],
})
