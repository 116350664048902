import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const appleMusicEmbedContentSchema = baseContentSchema.merge(
  z.object({
    websiteUrl: z.string().optional(),
    html: z.string().optional(),
  }),
)

export type AppleMusicEmbedContentSchema = z.infer<
  typeof appleMusicEmbedContentSchema
>

export const appleMusicEmbedConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'APPLE_MUSIC_EMBED',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 16,
  contentKey: 'appleMusicEmbedContent',
  schema: appleMusicEmbedContentSchema,
  name: t('apple_music_embed'),
  examples: [
    'https://music.apple.com/us/album/baby-youre-a-rich-man/1441163490',
  ],
  description: t('embed_apple_music_songs_to_enrich_your_page_content'),
  searchTags: [
    'apple_music',
    'songs',
    'spotify',
    'playlist',
    'embed',
    'iframe',
    'html',
    'embed the web',
  ],
})
