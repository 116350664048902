import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const loomEmbedContentSchema = baseContentSchema.merge(
  z.object({
    websiteUrl: z.string().optional(),
    html: z.string().optional(),
  }),
)

export type LoomEmbedContentSchema = z.infer<typeof loomEmbedContentSchema>

export const loomEmbedConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'LOOM_EMBED',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 16,
  contentKey: 'loomEmbedContent',
  schema: loomEmbedContentSchema,
  name: t('loom_embed'),
  examples: ['https://www.loom.com/share/f123cf3413a3496684019092367b3401'],
  description: t('embed_loom_screen_recordings_to_enrich_your_page_content'),
  searchTags: [
    'loom',
    'screen recording',
    'screenshare',
    'video',
    'embed',
    'iframe',
    'html',
    'embed the web',
  ],
})
