import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const soundcloudEmbedContentSchema = baseContentSchema.merge(
  z.object({
    websiteUrl: z.string().optional(),
    html: z.string().optional(),
  }),
)

export type SoundcloudEmbedContentSchema = z.infer<
  typeof soundcloudEmbedContentSchema
>

export const soundcloudEmbedConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'SOUNDCLOUD_EMBED',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 16,
  contentKey: 'soundcloudEmbedContent',
  schema: soundcloudEmbedContentSchema,
  name: t('soundcloud_embed'),
  examples: [
    'https://soundcloud.com/neweramusicofficial/newera-birds-in-the-sky',
  ],
  description: t('embed_soundcloud_tracks_to_enrich_your_page_content'),
  searchTags: [
    'soundcloud',
    'music',
    'track',
    'playlist',
    'song',
    'album',
    'embed',
    'iframe',
    'html',
    'embed the web',
  ],
})
