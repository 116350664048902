import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const socialIconGroupContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    socialIconGroupItems: z.array(
      z.object({
        key: z.string().optional(),
        websiteUrl: z.string().optional(),
      }),
    ),
  }),
)

export type SocialIconGroupContentSchema = z.infer<
  typeof socialIconGroupContentSchema
>

export const socialIconGroupConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'SOCIAL_ICON_GROUP',
  minW: 1,
  minH: 2,
  maxW: 4,
  maxH: 12,
  contentKey: 'socialIconGroupContent',
  schema: socialIconGroupContentSchema,
  name: t('social_icons'),
  description: t(
    'aggregate_multiple_social_media_links_into_one_unified_icon_group',
  ),
  searchTags: [
    'social',
    'icon',
    'groups',
    'apple',
    'discord',
    'email',
    'phone',
    'url',
    'facebook',
    'instagram',
    'linkedin',
    'pinterest',
    'snapchat',
    'soundcloud',
    'spotify',
    'tiktok',
    'twitch',
    'twitter',
    'whatsapp',
    'youtube',
    'github',
    'dribbble',
    'telegram',
    'grow and share',
    'Express yourself',
  ],
})
