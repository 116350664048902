import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const ratingsContentSchema = baseContentSchema.merge(
  z.object({
    locationId: z.string().optional(),
    googlePlaceId: z.string().optional(),
    showReviews: z.boolean().optional(),
    source: z.string().optional(),
    customRating: z.coerce.number().optional(),
    customRatingsTotal: z.coerce.number().optional(),
    customRatingsUrl: z.string().optional(),
  }),
)

export type RatingsContentSchema = z.infer<typeof ratingsContentSchema>

export const ratingsConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'RATINGS',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 10,
  contentKey: 'ratingsContent',
  schema: ratingsContentSchema,
  metaKey: 'ratingsMeta',
  name: t('ratings_and_reviews'),
  description: t('share_your_rating_with_your_contacts'),
  searchTags: ['ratings', 'reviews', 'google'],
})
