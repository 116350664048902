import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const xEmbedContentSchema = baseContentSchema.merge(
  z.object({
    websiteUrl: z.string().optional(),
    html: z.string().optional(),
  }),
)

export type XEmbedContentSchema = z.infer<typeof xEmbedContentSchema>

export const xEmbedConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'X_EMBED',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 16,
  contentKey: 'xEmbedContent',
  schema: xEmbedContentSchema,
  name: t('x_twitter_embed'),
  examples: ['https://twitter.com/Reza_Zadeh/status/1344009123004747778'],
  description: t('embed_x_twitter_posts_to_enrich_your_page_content'),
  searchTags: [
    'twitter',
    'x',
    'post',
    'tweet',
    'embed',
    'iframe',
    'html',
    'embed the web',
  ],
})
