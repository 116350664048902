import { PartialMessage } from '@bufbuild/protobuf'
import { Site } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/site_pb'
import { Workspace } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/workspace_pb'
import { makeAutoObservable } from 'mobx'
import { isHydrated, makePersistable } from 'mobx-persist-store'

import { SiteStatusItem } from '@/components/site/site-badge'

import { RootStore } from './root-store'

export type ContactDialog =
  | 'APPOINTMENTS'
  | 'MESSAGES'
  | 'FORM_RESPONSES'
  | 'INVOICES'
  | 'ORDERS'
  | 'OVERVIEW'
  | 'QUOTES'
  | 'REVIEW'
  | 'FILES'
  | 'DETAILS'
  | 'NOTES'

export class LocalStore {
  rootStore: RootStore
  currentSiteMeta: PartialMessage<Site> | null | undefined = null
  currentWorkspaceMeta: PartialMessage<Workspace> | null | undefined = null
  siteStatuses: SiteStatusItem[] = []
  isPro: boolean = false
  isSiteTemplate: boolean = false
  contactDialog: ContactDialog | null = null
  siteContactId: string | null = null
  bookingRequestsCount: number | null = null
  unfulfilledOrdersCount: number | null = null
  previousLinks: string[] = []
  canvaToken: string | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true })
    makePersistable(this, {
      name: 'fingertip-local-storage',
      properties: [
        'currentSiteMeta',
        'currentWorkspaceMeta',
        'bookingRequestsCount',
        'unfulfilledOrdersCount',
        'siteStatuses',
        'isPro',
        'isSiteTemplate',
        'previousLinks',
        'canvaToken',
      ],
      storage: typeof window !== 'undefined' ? window.localStorage : undefined,
    })
    this.rootStore = rootStore
  }

  setCurrentSiteMeta(site: PartialMessage<Site> | null | undefined) {
    this.currentSiteMeta = site
  }

  setCurrentWorkspaceMeta(
    workspace: PartialMessage<Workspace> | null | undefined,
  ) {
    this.currentWorkspaceMeta = workspace
  }

  setSiteStatuses(siteStatuses: SiteStatusItem[]) {
    this.siteStatuses = siteStatuses
  }

  setIsPro(isPro: boolean) {
    this.isPro = isPro
  }

  setIsSiteTemplate(isSiteTemplate: boolean) {
    this.isSiteTemplate = isSiteTemplate
  }

  get isHydrated() {
    return isHydrated(this)
  }

  setContactDialog(contactDialog: ContactDialog | null) {
    this.contactDialog = contactDialog
  }

  setSiteContactId(siteContactId: string | null) {
    this.siteContactId = siteContactId
  }

  setBookingRequestsCount(bookingRequestsCount: number | null) {
    this.bookingRequestsCount = bookingRequestsCount
  }

  setUnfulfilledOrdersCount(unfulfilledOrdersCount: number | null) {
    this.unfulfilledOrdersCount = unfulfilledOrdersCount
  }

  setCanvaToken(canvaToken: string | null) {
    this.canvaToken = canvaToken
  }

  addPreviousLink(previousLink: string) {
    // Ensure we have an array to work with
    if (!Array.isArray(this.previousLinks)) {
      this.previousLinks = []
    }

    // Make a copy with type safety
    const updatedLinks = Array.isArray(this.previousLinks)
      ? [...this.previousLinks]
      : []

    // Remove if exists
    const linkIndex = updatedLinks.indexOf(previousLink)
    if (linkIndex !== -1) {
      updatedLinks.splice(linkIndex, 1)
    }

    // Add to front
    updatedLinks.unshift(previousLink)

    // Keep only last 10
    this.previousLinks = updatedLinks.slice(0, 10)
  }
}
