import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const iconGroupContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    icons: z.array(
      z.object({
        media: z.any(),
        title: z.string().optional(),
        websiteUrl: z.string().optional(),
      }),
    ),
  }),
)

export type IconGroupContentSchema = z.infer<typeof iconGroupContentSchema>

export const iconGroupConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'ICON_GROUP',
  minW: 1,
  minH: 2,
  maxW: 4,
  maxH: 12,
  contentKey: 'iconGroupContent',
  schema: iconGroupContentSchema,
  name: t('icon_group'),
  description: t('display_multiple_links_as_a_group_of_icons'),
  searchTags: [
    'icon group',
    'app',
    'icons',
    'logos',
    'collection',
    'doordash',
    'ubereats',
    'me&u',
    'opentable',
    'foodie hub',
    'Sell products',
  ],
})
