import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

import { RootStore } from './root-store'

type TempStoreItem = {
  data: any
  expiresAt: number
}

export class TempStore {
  rootStore: RootStore
  tempItems: Record<string, TempStoreItem> = {}

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true })
    makePersistable(this, {
      name: 'fingertip-temp-storage',
      properties: ['tempItems'],
      storage: typeof window !== 'undefined' ? window.localStorage : undefined,
    })
    this.rootStore = rootStore
  }

  storeItem(key: string, data: any, expiresAt: number = Date.now() + 300000) {
    this.tempItems[key] = { data, expiresAt }
    // Set a timeout to automatically remove the item when it expires
    setTimeout(() => {
      delete this.tempItems[key]
    }, expiresAt - Date.now())
  }

  retrieveItem(key: string): any {
    const item = this.tempItems[key]
    if (item && item.expiresAt > Date.now()) {
      const returnValue = item.data
      // Remove item after retrieving it
      delete this.tempItems[key]
      return returnValue
    }
    // Ensure item is removed if it is expired even if not retrieved
    delete this.tempItems[key]
    return undefined
  }
}
