import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const mapContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    locationId: z.string().optional(),
    googlePlaceId: z.string().optional(),
    zoom: z.coerce.number().optional(),
  }),
)

export type MapContentSchema = z.infer<typeof mapContentSchema>

export const mapConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'MAP',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 12,
  contentKey: 'mapContent',
  schema: mapContentSchema,
  metaKey: 'mapMeta',
  name: t('map'),
  description: t(
    'display_your_location_to_make_it_easy_for_contacts_to_find_you',
  ),
  searchTags: [
    'maps',
    'location',
    'my business',
    'address',
    'marker',
    'google maps',
    'apple maps',
    'open street map',
    'mapbox',
    'interactive',
    'Help them find you',
  ],
})
