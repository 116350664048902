import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const portfolioContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    assets: z.array(
      z.object({
        media: z.any(),
        title: z.string().optional(),
        websiteUrl: z.string().optional(),
      }),
    ),
  }),
)

export type PortfolioContentSchema = z.infer<typeof portfolioContentSchema>

export const portfolioConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'PORTFOLIO',
  minW: 3,
  minH: 4,
  maxW: 4,
  maxH: 100,
  contentKey: 'portfolioContent',
  schema: portfolioContentSchema,
  name: t('image_grid'),
  description: t('share_a_grid_of_photos'),
  examples: [t('jpgs'), t('pngs'), t('gifs')],
  searchTags: [
    'image',
    'photo',
    'jpg',
    'png',
    'photo',
    'portfolio',
    'album',
    'gallery',
    'showcase',
    'grow and share',
    'Express yourself',
  ],
})
