import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const pageBreakContentSchema = baseContentSchema.merge(
  z.object({
    styleSlug: z.string().optional(),
  }),
)

export type PageBreakContentSchema = z.infer<typeof pageBreakContentSchema>

export const pageBreakConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'PAGE_BREAK',
  minW: 1,
  minH: 1,
  maxW: 4,
  maxH: 12,
  contentKey: 'pageBreakContent',
  schema: pageBreakContentSchema,
  name: t('page_dividers'),
  description: t('add_a_divider_to_your_page'),
  searchTags: [
    'page break',
    'spacing',
    'section',
    'hard rule',
    'divider',
    'hr',
    'line',
    'ellipsis',
    'spacer',
  ],
})
