import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

import { RootStore } from './root-store'

export type MessageConversationDialog =
  | 'MESSAGE_CONVERSATION_INFO'
  | 'NEW_MESSAGE_CONVERSATION'

export class MessageStore {
  rootStore: RootStore
  selectedSiteContactId: string | null = null
  messageConversationDialog: MessageConversationDialog | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true })
    makePersistable(this, {
      name: 'fingertip-conversation-storage',
      properties: ['selectedSiteContactId'],
      storage: typeof window !== 'undefined' ? window.localStorage : undefined,
    })
    this.rootStore = rootStore
  }

  setSelectedSiteContactId(id: string | null) {
    this.selectedSiteContactId = id
  }

  setMessageConversationDialog(dialog: MessageConversationDialog | null) {
    this.messageConversationDialog = dialog
  }
}
