import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const textContentSchema = baseContentSchema.merge(
  z.object({
    text: z.string().optional(),
    hideBackground: z.boolean().optional(),
  }),
)

export type TextContentSchema = z.infer<typeof textContentSchema>

export const textConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'TEXT',
  minW: 2,
  minH: 3,
  maxW: 4,
  maxH: 20,
  contentKey: 'textContent',
  schema: textContentSchema,
  name: t('text'),
  description: t('add_text_to_your_page'),
  searchTags: [
    'text',
    'editor',
    'rich',
    'body',
    'description',
    'about',
    'html',
    'heading',
    'quote',
    'ai',
    'magic',
    'header',
    'copy',
    'Express yourself',
  ],
})
