import { Layout } from '@fingertip/react-grid-layout'

export const findEmptyPosition = ({
  layouts,
  newItemWidth,
  newItemHeight,
  gridWidth,
  gridHeight,
}: {
  layouts: Layout
  newItemWidth: number
  newItemHeight: number
  gridWidth: number
  gridHeight: number
}): { x: number; y: number } => {
  // Step 1: Initialize a 2D array
  const grid = Array.from({ length: gridHeight }, () =>
    Array.from({ length: gridWidth }, () => false),
  )

  // Step 2: Mark occupied cells
  layouts.forEach((item) => {
    for (let x = item.x; x < item.x + item.w; x++) {
      for (let y = item.y; y < item.y + item.h; y++) {
        if (grid[y] && grid[y][x] !== undefined) {
          grid[y][x] = true
        }
      }
    }
  })

  // Step 3: Find an empty space
  for (let y = 0; y <= gridHeight - newItemHeight; y++) {
    for (let x = 0; x <= gridWidth - newItemWidth; x++) {
      let hasSpace = true

      for (let dx = 0; dx < newItemWidth; dx++) {
        for (let dy = 0; dy < newItemHeight; dy++) {
          if (grid[y + dy][x + dx]) {
            hasSpace = false
            break
          }
        }
        if (!hasSpace) break
      }

      if (hasSpace) {
        // Return position if an empty space is found
        return { x, y }
      }
    }
  }

  // Return null if no space found
  return {
    x: 0,
    y: Infinity,
  }
}
