import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const blogPostContentSchema = baseContentSchema.merge(
  z.object({
    buttonText: z.string().optional(),
    blogPostIds: z.array(z.string()).optional(),
  }),
)

export type BlogPostContentSchema = z.infer<typeof blogPostContentSchema>

export const blogPostConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'BLOG_POST',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 12,
  contentKey: 'blogPostContent',
  schema: blogPostContentSchema,
  metaKey: 'blogPostMeta',
  name: t('nav_blog_posts'),
  description: t('display_blog_posts_on_fingertip'),
  searchTags: [
    'posts',
    'blog',
    'wordpress',
    'articles',
    'content',
    'tools',
    'tell them more',
    'Express yourself',
  ],
})
