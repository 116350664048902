import { StoreConfig } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/store_config_pb'
import { UserProfile } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/user_profile_pb'
import { Group1Icon } from '@fingertip/icons'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { BookerMemberCard } from '@/components/booking/booker/components/booker-member-card'
import { Card } from '@/components/ui/card'
import { BookingStage } from '@/lib/stores/booker-store'
import { RootStoreContext } from '@/lib/stores/root-store'
import { cn } from '@/lib/utils'

type Props = {
  members?: UserProfile[]
  storeConfig?: StoreConfig
  nextStage: BookingStage | null
}

export const ALLOW_ANY_PROFESSIONAL = true

export const BookerStageMemberPicker = observer(({ members }: Props) => {
  const { t } = useTranslation()
  const {
    bookerStore: { selectedUserId, setSelectedUserId },
  } = useContext(RootStoreContext)

  return (
    <div className="size-full">
      <div className="max-w-3xl mx-auto mb-4">
        <h1 className="text-xl font-bold mb-6">{t('choose_who_to_book_w')}</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {ALLOW_ANY_PROFESSIONAL && (
            <Card
              onClick={() => {
                setSelectedUserId(null)
              }}
              className={cn(
                'p-6 rounded-lg cursor-pointer transition-all duration-100 border-2',
                {
                  'border-primary': !selectedUserId,
                  'border-transparent': !!selectedUserId,
                },
              )}
            >
              <div className="flex flex-col items-center gap-4">
                <div className="size-20 rounded-full bg-muted/50 border-muted border flex items-center justify-center">
                  <Group1Icon className="size-5 text-muted-foreground" />
                </div>

                <h3 className="text-center font-medium text-muted-foreground">
                  {t('any_available_profes')}
                </h3>
              </div>
            </Card>
          )}

          {members?.map((member) => (
            <BookerMemberCard
              key={member.id}
              profile={member}
              isSelected={selectedUserId === member.id}
              onSelect={() => {
                setSelectedUserId(member.id)
              }}
            />
          ))}
        </div>
      </div>
    </div>
  )
})
