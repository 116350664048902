import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const imageComparisonContentSchema = baseContentSchema.merge(
  z.object({
    assets: z.array(
      z.object({
        media: z.any(),
        title: z.string().optional(),
      }),
    ),
  }),
)

export type ImageComparisonContentSchema = z.infer<
  typeof imageComparisonContentSchema
>

export const imageComparisonConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'IMAGE_COMPARISON',
  minW: 1,
  minH: 2,
  maxW: 4,
  maxH: 12,
  contentKey: 'imageComparisonContent',
  schema: imageComparisonContentSchema,
  name: t('image_comparison'),
  description: t('compare_2_images_or_show_before_and_after'),
  searchTags: [
    'scrub',
    'after',
    'before',
    'image comparison',
    'interactive',
    'tell them more',
  ],
})
