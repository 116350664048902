import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const faqContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    description: z.string().optional(),
    faqs: z
      .array(
        z.object({
          question: z.string().optional(),
          answer: z.string().optional(),
        }),
      )
      .optional(),
    buttonText: z.string().optional(),
  }),
)

export type FaqContentSchema = z.infer<typeof faqContentSchema>

export const faqConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'FAQ',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 12,
  contentKey: 'faqContent',
  schema: faqContentSchema,
  name: t('faqs'),
  description: t('answer_common_questions_to_assist_and_guide_your_contacts'),
  searchTags: [
    'faqs',
    'frequently',
    'asked',
    'questions',
    'answers',
    'help',
    'info',
    'tell them more',
  ],
})
