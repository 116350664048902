import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const affiliateContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    websiteUrl: z.string().optional(),
  }),
)

export type AffiliateContentSchema = z.infer<typeof affiliateContentSchema>

export const affiliateConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'AFFILIATE',
  minW: 2,
  minH: 3,
  maxW: 4,
  maxH: 24,
  contentKey: 'affiliateContent',
  schema: affiliateContentSchema,
  name: t('fingertip_affiliate_'),
  description: t('give_20_off_get_up_t'),
  searchTags: ['cello', 'discount', 'affiliate', 'refer'],
})
