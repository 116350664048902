import { PartialMessage } from '@bufbuild/protobuf'
import {
  BlockContent,
  ContactContent,
  FormContent,
  PollContent,
  ProductContent,
  SocialIconGroupContent,
} from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { Page } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/page_pb'
import { sentenceCase } from 'change-case'

import { BlockType } from '@/components/blocks/shared/types'

export const transformBlockContent = ({
  block,
  page,
  type,
}: {
  block: BlockType
  page?: PartialMessage<Page>
  type: 'PLACEHOLDER' | 'DUPLICATE'
}): BlockType => {
  let content: PartialMessage<BlockContent> | undefined = block.content

  if (block.kind === 'SOCIAL_ICON_GROUP' && type === 'PLACEHOLDER') {
    const socialIconGroupContent: PartialMessage<SocialIconGroupContent> = {
      socialIconGroupItems:
        (page?.socialIcons || []).length > 1
          ? (page?.socialIcons || []).map((socialIcon) => ({
              key: socialIcon.key,
              websiteUrl: socialIcon.url,
            }))
          : (content?.content?.value as any)?.socialIconGroupItems,
    }

    return {
      ...block,
      content: {
        content: {
          ...(content?.content as any),
          value: {
            ...content?.content?.value,
            ...socialIconGroupContent,
          },
        },
      },
    }
  } else if (block.kind === 'CONTACT' && type === 'PLACEHOLDER') {
    const contactContent: PartialMessage<ContactContent> = {
      fullName: page?.name,
      organization: '',
      jobTitle: '',
      address: '',
      note: '',
      media: page?.logoMedia || page?.bannerMedia,
      items:
        (page?.socialIcons || []).length > 1
          ? (page?.socialIcons || []).map((socialIcon) => {
              switch (socialIcon.key) {
                case 'email':
                  return {
                    type: 'EMAIL',
                    label: 'Work',
                    key: socialIcon.key,
                    value: socialIcon.url,
                  }
                case 'phone':
                  return {
                    type: 'TEL',
                    label: 'Mobile',
                    key: socialIcon.key,
                    value: socialIcon.url,
                  }

                default:
                  return {
                    type: 'URL',
                    label: sentenceCase(socialIcon?.key || ''),
                    key: socialIcon.key,
                    value: socialIcon.url,
                  }
              }
            })
          : (content?.content?.value as any)?.items,
    }

    return {
      ...block,
      content: {
        content: {
          ...(content?.content as any),
          value: {
            ...content?.content?.value,
            ...contactContent,
          },
        },
      },
    }
  } else if (block.kind === 'FORM') {
    const formContent: PartialMessage<FormContent> = {
      formTemplateId: undefined,
    }

    return {
      ...block,
      content: {
        content: {
          ...(content?.content as any),
          value: {
            ...content?.content?.value,
            ...formContent,
          },
        },
      },
    }
  } else if (block.kind === 'POLL') {
    const pollContent: PartialMessage<PollContent> = {
      pollId: undefined,
    }

    return {
      ...block,
      content: {
        content: {
          ...(content?.content as any),
          value: {
            ...content?.content?.value,
            ...pollContent,
          },
        },
      },
    }
  } else if (block.kind === 'PRODUCT') {
    const productContent: PartialMessage<ProductContent> = {
      productIds: undefined,
    }

    return {
      ...block,
      content: {
        content: {
          ...(content?.content as any),
          value: {
            ...content?.content?.value,
            ...productContent,
          },
        },
      },
    }
  } else {
    return block
  }
}
