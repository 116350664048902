import { PartialMessage } from '@bufbuild/protobuf'
import { FormField } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/form_field_pb'
import { makeAutoObservable } from 'mobx'

import { RootStore } from './root-store'

export type FormEditorDialog = 'SLUG' | 'RECIPIENTS' | 'SHARE'

export class FormEditorStore {
  rootStore: RootStore
  formEditorDialog: FormEditorDialog | null = null
  formFields: PartialMessage<FormField>[] = []

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true })
    this.rootStore = rootStore
  }

  setFormEditorDialog(dialog: FormEditorDialog | null) {
    this.formEditorDialog = dialog
  }

  setFormFields(fields: PartialMessage<FormField>[]) {
    this.formFields = fields || []
  }
}
