import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const skillsContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    skills: z.array(
      z.object({
        title: z.string().optional(),
      }),
    ),
  }),
)

export type SkillsContentSchema = z.infer<typeof skillsContentSchema>

export const skillsConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'SKILLS',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 12,
  contentKey: 'skillsContent',
  schema: skillsContentSchema,
  name: t('top_skills'),
  description: t('show_off_your_top_skills'),
  searchTags: ['skills', 'linkedin', 'personal', 'grow and share'],
})
