import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const calendarContentSchema = baseContentSchema.merge(
  z.object({
    eventTitle: z.string().optional(),
    startDate: z.string().optional(),
    startTime: z.string().optional(),
    endDate: z.string().optional(),
    endTime: z.string().optional(),
    location: z.string().optional(),
    description: z.string().optional(),
    timezone: z.string().optional(),
    buttonText: z.string().optional(),
  }),
)

export type CalendarContentSchema = z.infer<typeof calendarContentSchema>

export const calendarConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'CALENDAR',
  minW: 2,
  minH: 4,
  maxW: 4,
  maxH: 12,
  contentKey: 'calendarContent',
  schema: calendarContentSchema,
  name: t('calendar_event'),
  description: t('create_an_event_that_everyone_wants_to_join_and_attend'),
  searchTags: ['calendar', 'event', 'calendly', 'booking', 'tell them more'],
})
