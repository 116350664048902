import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const projectContentSchema = baseContentSchema.merge(
  z.object({
    assets: z.array(
      z.object({
        media: z.any(),
        title: z.string().optional(),
        description: z.string().optional(),
        websiteUrl: z.string().optional(),
      }),
    ),
  }),
)

export type ProjectContentSchema = z.infer<typeof projectContentSchema>

export const projectConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'PROJECT',
  minW: 2,
  minH: 8,
  maxW: 4,
  maxH: 12,
  contentKey: 'projectContent',
  schema: projectContentSchema,
  name: t('projects'),
  description: t('showcase_projects_with_an_image_carousel'),
  examples: ['JPGs', 'PNGs', 'GIFs'],
  searchTags: [
    'image',
    'photo',
    'jpg',
    'png',
    'photo',
    'project',
    'album',
    'gallery',
    'showcase',
    'grow and share',
  ],
})
