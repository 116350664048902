import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const servicesContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    description: z.string().optional(),
    services: z.array(
      z.object({
        title: z.string().optional(),
        price: z.string().optional(),
        unit: z.string().optional(),
      }),
    ),
  }),
)

export type ServicesContentSchema = z.infer<typeof servicesContentSchema>

export const servicesConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'SERVICES',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 12,
  contentKey: 'servicesContent',
  schema: servicesContentSchema,
  name: t('services'),
  description: t('show_off_what_services_you_offer'),
  searchTags: ['services', 'price list', 'tell them more'],
})
