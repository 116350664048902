import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const galleryContentSchema = baseContentSchema.merge(
  z.object({
    assets: z.array(
      z.object({
        media: z.any(),
        title: z.string().optional(),
        websiteUrl: z.string().optional(),
      }),
    ),
    hideBackground: z.boolean().optional(),
  }),
)

export type GalleryContentSchema = z.infer<typeof galleryContentSchema>

export const galleryConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'GALLERY',
  minW: 1,
  minH: 2,
  maxW: 4,
  maxH: 24,
  contentKey: 'galleryContent',
  schema: galleryContentSchema,
  name: t('photos'),
  description: t('display_a_gallery_of'),
  examples: ['JPGs', 'PNGs', 'GIFs'],
  searchTags: [
    'image',
    'photo',
    'jpg',
    'png',
    'photo',
    'gallery',
    'album',
    'Express yourself',
    'grow and share',
  ],
})
