import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const contactContentSchema = baseContentSchema.merge(
  z.object({
    fullName: z.string().optional(),
    organization: z.string().optional(),
    address: z.string().optional(),
    note: z.string().optional(),
    jobTitle: z.string().optional(),
    items: z.array(
      z.object({
        type: z.string().optional(),
        label: z.string().optional(),
        value: z.string().optional(),
        key: z.string().optional(),
      }),
    ),
    media: z.any(),
  }),
)

export type ContactContentSchema = z.infer<typeof contactContentSchema>

export const contactConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'CONTACT',
  minW: 2,
  minH: 4,
  maxW: 4,
  maxH: 12,
  contentKey: 'contactContent',
  schema: contactContentSchema,
  name: t('contact_details'),
  description: t('have_contacts_save_your_details_staight_to_their_phone'),
  searchTags: [
    'contact',
    'save details',
    'phone',
    'email',
    'socials',
    'card',
    'profile',
    'user',
    'personal',
    'Help them find you',
  ],
})
