import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const productContentSchema = baseContentSchema.merge(
  z.object({
    products: z.array(
      z.object({
        productName: z.string().optional(),
        websiteUrl: z.string().optional(),
        productDescription: z.string().optional(),
        productPrice: z.string().optional(),
        productSku: z.string().optional(),
        productCurrency: z.string().optional(),
        media: z.any(),
      }),
    ),
    productIds: z.array(z.string()).optional(),
    buttonText: z.string().optional(),
    source: z.string().optional(),
  }),
)

export type ProductContentSchema = z.infer<typeof productContentSchema>

export const productConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'PRODUCT',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 16,
  contentKey: 'productContent',
  metaKey: 'productMeta',
  schema: productContentSchema,
  name: t('nav_products'),
  description:
    'Sell anything from downloads to courses, or offer custom services and website links',
  searchTags: [
    'shopify',
    'e-commerce',
    'product',
    'store',
    'Sell products',
    'digital',
    'products',
    'ecommerce',
    'shopify',
    'store',
    'tools',
    'Sell products',
    'courses',
    'guides',
    'ask me anything',
    'ebooks',
    'external link',
    'requests',
    'audits',
  ],
})
