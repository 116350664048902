import { makeAutoObservable } from 'mobx'

import { RootStore } from './root-store'

type CheckoutStage = 'PAYMENT' | 'CONFIRMED'

export class CheckoutStore {
  rootStore: RootStore
  stage: CheckoutStage | undefined = 'PAYMENT'
  clientSecret: string | undefined
  paymentIntentId: string | undefined
  targetOrderId: string | undefined

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true })
    this.rootStore = rootStore
  }

  setStage(stage: CheckoutStage | undefined) {
    this.stage = stage
  }

  setClientSecret(clientSecret: string | undefined) {
    this.clientSecret = clientSecret
  }

  setPaymentIntentId(paymentIntentId: string | undefined) {
    this.paymentIntentId = paymentIntentId
  }

  setTargetOrderId(targetOrderId: string | undefined) {
    this.targetOrderId = targetOrderId
  }

  reset() {
    this.stage = 'PAYMENT'
    this.clientSecret = undefined
    this.paymentIntentId = undefined
    this.targetOrderId = undefined
  }
}
