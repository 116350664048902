import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const weatherContentSchema = baseContentSchema.merge(
  z.object({
    locationId: z.string().optional(),
    googlePlaceId: z.string().optional(),
    units: z.string().optional(),
  }),
)

export type WeatherContentSchema = z.infer<typeof weatherContentSchema>

export const weatherConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'WEATHER',
  minW: 2,
  minH: 4,
  maxW: 4,
  maxH: 12,
  contentKey: 'weatherContent',
  schema: weatherContentSchema,
  metaKey: 'weatherMeta',
  name: t('weather'),
  description: t('show_the_weather_in_your_city'),
  searchTags: [
    'weather',
    'rain',
    'forecast',
    'temperature',
    'celcius',
    'Help them find you',
  ],
})
