import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const quotationContentSchema = baseContentSchema.merge(
  z.object({
    text: z.string().optional(),
    fullName: z.string().optional(),
    media: z.any(),
  }),
)

export type QuotationContentSchema = z.infer<typeof quotationContentSchema>

export const quotationConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'QUOTATION',
  minW: 1,
  minH: 4,
  maxW: 4,
  maxH: 12,
  contentKey: 'quotationContent',
  schema: quotationContentSchema,
  name: t('famous_quotes'),
  description: t('showcase_famous_memorable_quotes'),
  searchTags: [
    'quote',
    'quotation',
    'famous',
    'memorable',
    'celebrity',
    'Express yourself',
  ],
})
