import { makeAutoObservable } from 'mobx'

import { RootStore } from './root-store'

export type ContactDialogStage = 'SAVE' | 'SEND' | 'CREATE'

export class PublicStore {
  rootStore: RootStore
  dialogBlockId: string | null = null
  contactDialogStage: ContactDialogStage = 'SAVE'
  shouldRefetchCart: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true })
    this.rootStore = rootStore
  }

  setDialogBlockId(dialogBlockId: string | null) {
    this.dialogBlockId = dialogBlockId
  }

  setContactDialogStage(contactDialogStage: ContactDialogStage) {
    this.contactDialogStage = contactDialogStage
  }

  setShouldRefetchCart(shouldRefetchCart: boolean) {
    this.shouldRefetchCart = shouldRefetchCart
  }
}
