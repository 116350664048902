import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

import { DEFAULT_WORKSPACE_SIDEBAR_WIDTH } from '@/components/sidebar/constants'

import { RootStore } from './root-store'

export class WorkspaceSidebarStore {
  rootStore: RootStore

  mobileIsOpen: boolean = false
  desktopWidth: number = DEFAULT_WORKSPACE_SIDEBAR_WIDTH
  isDragging: boolean = false
  activeId: string | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true })
    makePersistable(this, {
      name: 'fingertip-workspace-sidebar-storage',
      properties: ['mobileIsOpen', 'desktopWidth'],
      storage: typeof window !== 'undefined' ? window.localStorage : undefined,
    })
    this.rootStore = rootStore
  }

  setMobileIsOpen(mobileIsOpen: boolean) {
    this.mobileIsOpen = mobileIsOpen
  }

  setDesktopWidth(desktopSidebarWidth: number) {
    this.desktopWidth = desktopSidebarWidth
  }

  setIsDragging(isDragging: boolean) {
    this.isDragging = isDragging
  }

  onLinkClick() {
    this.setMobileIsOpen(false)
  }

  onSidebarOpen() {
    this.setDesktopWidth(DEFAULT_WORKSPACE_SIDEBAR_WIDTH)
    this.setMobileIsOpen(false)
  }

  setActiveId(activeId: string | null) {
    this.activeId = activeId
  }
}
