import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const openingHoursContentSchema = baseContentSchema.merge(
  z.object({
    locationId: z.string().optional(),
    googlePlaceId: z.string().optional(),
    useManualOverride: z.boolean().optional(),
    alwaysOpen: z.boolean().optional(),
    hideOpenToday: z.boolean().optional(),
    title: z.string().optional(),
    monday: z
      .object({
        times: z
          .array(
            z
              .object({
                startTime: z.string().optional(),
                endTime: z.string().optional(),
              })
              .optional(),
          )
          .optional(),
      })
      .optional(),
    tuesday: z
      .object({
        times: z
          .array(
            z
              .object({
                startTime: z.string().optional(),
                endTime: z.string().optional(),
              })
              .optional(),
          )
          .optional(),
      })
      .optional(),
    wednesday: z
      .object({
        times: z
          .array(
            z
              .object({
                startTime: z.string().optional(),
                endTime: z.string().optional(),
              })
              .optional(),
          )
          .optional(),
      })
      .optional(),
    thursday: z
      .object({
        times: z
          .array(
            z
              .object({
                startTime: z.string().optional(),
                endTime: z.string().optional(),
              })
              .optional(),
          )
          .optional(),
      })
      .optional(),
    friday: z
      .object({
        times: z
          .array(
            z
              .object({
                startTime: z.string().optional(),
                endTime: z.string().optional(),
              })
              .optional(),
          )
          .optional(),
      })
      .optional(),
    saturday: z
      .object({
        times: z
          .array(
            z
              .object({
                startTime: z.string().optional(),
                endTime: z.string().optional(),
              })
              .optional(),
          )
          .optional(),
      })
      .optional(),
    sunday: z
      .object({
        times: z
          .array(
            z
              .object({
                startTime: z.string().optional(),
                endTime: z.string().optional(),
              })
              .optional(),
          )
          .optional(),
      })
      .optional(),
  }),
)

export type OpeningHoursContentSchema = z.infer<
  typeof openingHoursContentSchema
>

export const openingHoursConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'OPENING_HOURS',
  minW: 2,
  minH: 4,
  maxW: 4,
  maxH: 12,
  contentKey: 'openingHoursContent',
  metaKey: 'openingHoursMeta',
  schema: openingHoursContentSchema,
  name: t('opening_hours'),
  description: t('share_what_times_youre_open_every_day'),
  searchTags: ['timetable', 'opening hours', 'schedule', 'Help them find you'],
})
