import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const bannerContentSchema = baseContentSchema.merge(
  z.object({
    text: z.string().optional(),
    media: z.any(),
  }),
)

export type BannerContentSchema = z.infer<typeof bannerContentSchema>

export const bannerConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'BANNER',
  minW: 2,
  minH: 3,
  maxW: 4,
  maxH: 12,
  contentKey: 'bannerContent',
  schema: bannerContentSchema,
  name: t('banner'),
  description: t('display_a_large_eye-catching_banner'),
  searchTags: ['banner', 'text', 'header', 'hero', 'section', 'image'],
})
