import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const serviceAreaContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    description: z.string().optional(),
    serviceAreas: z.array(
      z.object({
        title: z.string().optional(),
      }),
    ),
  }),
)

export type ServiceAreaContentSchema = z.infer<typeof serviceAreaContentSchema>

export const serviceAreasConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'SERVICE_AREAS',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 12,
  contentKey: 'serviceAreasContent',
  schema: serviceAreaContentSchema,
  name: t('service_areas'),
  description: t('what_areas_do_you_operate'),
  searchTags: ['service areas', 'locations'],
})
