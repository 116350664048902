import { makeAutoObservable } from 'mobx'

import { RootStore } from './root-store'

export type ConfirmBookingStage = 'DETAILS' | 'PAYMENT' | 'REDIRECT'

export class ConfirmBookingStore {
  rootStore: RootStore
  stage: ConfirmBookingStage = 'DETAILS'
  clientSecret: string | undefined
  paymentIntentId: string | undefined
  setupIntentId: string | undefined
  siteContactId: string | undefined
  bookingId: string | undefined
  invoiceId: string | undefined
  receiptEmail: string | undefined
  amountToPayInCents: number | undefined

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true })
    this.rootStore = rootStore
  }

  setStage(stage: ConfirmBookingStage) {
    this.stage = stage
  }

  setClientSecret(clientSecret: string | undefined) {
    this.clientSecret = clientSecret
  }

  setPaymentIntentId(paymentIntentId: string | undefined) {
    this.paymentIntentId = paymentIntentId
  }

  setSetupIntentId(setupIntentId: string | undefined) {
    this.setupIntentId = setupIntentId
  }

  setSiteContactId(siteContactId: string | undefined) {
    this.siteContactId = siteContactId
  }

  setBookingId(bookingId: string | undefined) {
    this.bookingId = bookingId
  }

  setInvoiceId(invoiceId: string | undefined) {
    this.invoiceId = invoiceId
  }

  setReceiptEmail(receiptEmail: string | undefined) {
    this.receiptEmail = receiptEmail
  }

  setAmountToPayInCents(amountToPayInCents: number | undefined) {
    this.amountToPayInCents = amountToPayInCents
  }

  reset() {
    this.stage = 'DETAILS'
    this.clientSecret = undefined
    this.paymentIntentId = undefined
    this.setupIntentId = undefined
    this.siteContactId = undefined
    this.bookingId = undefined
    this.receiptEmail = undefined
    this.amountToPayInCents = undefined
  }
}
