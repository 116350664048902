import { Layout, LayoutItem } from '@fingertip/react-grid-layout'

import { findEmptyPosition } from '@/lib/utils/layout-grid'

import {
  BLOCK_COLUMNS,
  BLOCK_GAP,
  BLOCK_HEIGHT,
  DEFAULT_WIDTH,
} from './constants'

type WidgetStyle = 'small' | 'medium' | 'large' | 'long'
export type ImagePosition = 'TOP' | 'RIGHT' | 'CENTER' | null

export const getSm = ({
  layouts,
  selectedBlockId,
  currentBlock,
}: {
  layouts: Layout
  selectedBlockId?: string | null
  currentBlock?: { w: number; h: number }
}) => {
  const foundItem = selectedBlockId
    ? layouts.find((item) => item.i === selectedBlockId)
    : undefined

  let sm

  if (foundItem && currentBlock) {
    // Calculate the position directly beneath the found item
    const beneathPositionY = foundItem.y + foundItem.h - 1

    sm = {
      x: foundItem.x, // same x-coordinate as the selected block
      y: beneathPositionY, // directly underneath the selected block
      w: currentBlock.w || 1,
      h: currentBlock.h || 1,
    }
  } else if (foundItem) {
    // Calculate the position directly beneath the found item
    const beneathPositionY = foundItem.y + foundItem.h - 1

    sm = {
      x: foundItem.x,
      y: beneathPositionY,
      w: foundItem.w,
      h: foundItem.h,
    }
  } else if (currentBlock) {
    const position = findEmptyPosition({
      layouts,
      newItemHeight: currentBlock?.h || 1,
      newItemWidth: currentBlock?.w || 1,
      gridWidth: BLOCK_COLUMNS,
      gridHeight: 1000,
    })

    sm = {
      x: position.x,
      y: position.y,
      w: currentBlock.w || 1,
      h: currentBlock.h || 1,
    }
  }

  return sm
}

export const findBlockYPositionAboveOrBelow = ({
  direction,
  layout,
  layouts,
}: {
  direction: 'UP' | 'DOWN'
  layout: LayoutItem
  layouts: Layout
}): number => {
  let targetY = layout.y
  let targetFound: LayoutItem | null = null
  let smallestDistance = Number.MAX_VALUE

  layouts.forEach((itemLayout) => {
    if (itemLayout.i === layout.i) {
      return
    }
    const hasHorizontalOverlap = !(
      itemLayout.x >= layout.x + layout.w ||
      itemLayout.x + itemLayout.w <= layout.x
    )

    if (!hasHorizontalOverlap) {
      return
    }

    if (direction === 'DOWN' && itemLayout.y > layout.y) {
      const distanceY = itemLayout.y - (layout.y + layout.h)
      if (distanceY < smallestDistance) {
        smallestDistance = distanceY
        targetY = itemLayout.y + 1 // Position to place the current block directly above the target block
        targetFound = itemLayout
      }
    } else if (direction === 'UP' && itemLayout.y < layout.y) {
      const distanceY = layout.y - (itemLayout.y + itemLayout.h)
      if (distanceY < smallestDistance) {
        smallestDistance = distanceY
        targetY = itemLayout.y - 1 // Adjust for UP to get bottom Y of the block above
        targetFound = itemLayout // Suitable target found
      }
    }
  })

  // If no suitable target is found and direction is UP, try to set to the highest possible position
  if (!targetFound && direction === 'UP') {
    targetY = 0 // Assume 0 is the topmost position if no block is above
  }

  return targetY // Return the y position of the target block
}

export const getBlockScrollTop = ({ blockId }: { blockId: string }) => {
  const blockElement = document.getElementById(blockId)

  if (!blockElement) {
    return 0
  }

  const blockRect = blockElement.getBoundingClientRect()

  return blockRect.top
}

export const scrollBlock = ({
  blockId,
  oldTop,
}: {
  blockId: string
  oldTop: number
}) => {
  const newTop = getBlockScrollTop({ blockId })

  const deltaOffset = newTop - oldTop

  // Scrolling the specified container
  const scrollContainer = document.getElementById('scroll-container')

  if (!scrollContainer) {
    return
  }

  const desiredScrollPosition = scrollContainer.scrollTop + deltaOffset

  scrollContainer.scrollTo({
    top: desiredScrollPosition,
    behavior: 'smooth',
  })
}

export const getWidgetStyle = (w: number, h: number): WidgetStyle => {
  if (h >= w * 2 && h <= 7 && w <= 3) {
    return 'small'
  } else if (w >= 2 && w <= 4 && (h === 2 || h === 3)) {
    return 'long'
  } else if (w <= h && w * 2 > h) {
    return 'medium'
  } else {
    return 'large'
  }
}

export const isWidgetMinimum = (w: number, h: number) => {
  const style = getWidgetStyle(w, h)
  switch (style) {
    case 'small':
      return w === 2 && h === 4
    case 'medium':
      return h === 4
    case 'large':
      return w === 4 && h === 8
    case 'long':
      return h === 2
  }
}

export const formatLargeNumbers = (count: number) => {
  if (count >= 1000000) {
    return `${(count / 1000000).toFixed(1)}m`
  } else if (count >= 1000) {
    return `${Math.floor(count / 1000)}k`
  } else {
    return count.toString()
  }
}

export const getTitleMaxLines = (
  widgetMinimum: boolean,
  widgetStyle: WidgetStyle,
): number => {
  if (widgetMinimum && widgetStyle !== 'large') {
    return 1
  }

  return 2
}

export const getDescriptionMaxLines = (
  widgetMinimum: boolean,
  widgetStyle: WidgetStyle,
  h: number,
  hasImage: boolean,
  position: ImagePosition,
): number => {
  if (widgetMinimum && widgetStyle !== 'large') {
    return 1
  }

  const rightOrNoImage = !hasImage || position === 'RIGHT' || position === 'TOP'

  if (!rightOrNoImage) {
    return 2
  }

  if (h > 6) {
    return 3
  }

  return 2
}

export const getFullHeight = (h: number, scaleFactor: number) => {
  const fullHeight = h * (BLOCK_HEIGHT + BLOCK_GAP) - BLOCK_GAP
  return Math.round(fullHeight * scaleFactor)
}

export const getFullWidth = (w: number, scaleFactor: number) => {
  const availableWidth = DEFAULT_WIDTH - BLOCK_GAP * (BLOCK_COLUMNS - 1)
  const columnWidth = Math.floor(availableWidth / BLOCK_COLUMNS)
  const fullWidth = columnWidth * w + BLOCK_GAP * (w - 1)
  return Math.round(fullWidth * scaleFactor)
}
