import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const twitchEmbedContentSchema = baseContentSchema.merge(
  z.object({
    websiteUrl: z.string().optional(),
    html: z.string().optional(),
  }),
)

export type TwitchEmbedContentSchema = z.infer<typeof twitchEmbedContentSchema>

export const twitchEmbedConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'TWITCH_EMBED',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 16,
  contentKey: 'twitchEmbedContent',
  schema: twitchEmbedContentSchema,
  name: t('twitch_embed'),
  examples: ['https://www.twitch.tv/videos/992175865'],
  description: t('embed_twitch_streams_to_enrich_your_page_content'),
  searchTags: [
    'twitch',
    'streaming',
    'gaming',
    'video',
    'embed',
    'iframe',
    'html',
    'embed the web',
  ],
})
