import { z } from 'zod'

export const baseContentSchema = z.object({
  blockId: z.string().nullable(),
  version: z.number().optional(),
  sm: z
    .object({
      x: z.number(),
      y: z.number(),
      w: z.number(),
      h: z.number(),
    })
    .optional(),
})
