import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

import {
  DEFAULT_SITE_SIDEBAR_WIDTH,
  SITE_SIDEBAR_NAV_WIDTH,
} from '@/components/sidebar/constants'

import { RootStore } from './root-store'

export class SiteSidebarStore {
  rootStore: RootStore

  desktopIsOpen: boolean = true
  desktopWidth: number = DEFAULT_SITE_SIDEBAR_WIDTH
  isDragging: boolean = false
  activeId: string | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true })
    makePersistable(this, {
      name: 'fingertip-site-sidebar-storage',
      properties: ['desktopWidth'],
      storage: typeof window !== 'undefined' ? window.localStorage : undefined,
    })
    this.rootStore = rootStore
  }

  setDesktopIsOpen(desktopIsOpen: boolean) {
    this.desktopIsOpen = desktopIsOpen
  }

  setDesktopSidebarWidth(desktopSidebarWidth: number) {
    this.desktopWidth = desktopSidebarWidth
  }

  setIsDragging(isDragging: boolean) {
    this.isDragging = isDragging
  }

  setActiveId(activeId: string | null) {
    this.activeId = activeId
  }

  get isDesktopVisible() {
    const { pageEditorDialog } = this.rootStore.pageEditorStore
    return (
      !!pageEditorDialog &&
      [
        'ADMIN',
        'EDIT_THEME',
        'EDIT_BLOCK',
        'ADD_BLOCK',
        'ADD_BLOCK_SIZE',
        'EDIT_HEADER',
        'EDIT_FOOTER',
        'EDIT_PAGE',
        'EDIT_MESSAGE_THEME',
        'EDIT_FORM_FIELD',
        'ADD_FORM_FIELD',
        'EDIT_FORM_SETTINGS',
      ].includes(pageEditorDialog)
    )
  }

  get getDesktopSidebarWidth() {
    return this.desktopIsOpen && this.isDesktopVisible
      ? this.desktopWidth
      : SITE_SIDEBAR_NAV_WIDTH
  }
}
