import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const bookingContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    description: z.string().optional(),
    media: z.any(),
    buttonText: z.string().optional(),
    websiteUrl: z.string().optional(),
    source: z.string().optional(),
  }),
)

export type BookingContentSchema = z.infer<typeof bookingContentSchema>

export const bookingConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'BOOKING',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 12,
  contentKey: 'bookingContent',
  schema: bookingContentSchema,
  name: t('appointment_scheduli'),
  description: t('allow_users_to_book_appointments_or_services_directly'),
  searchTags: [
    'booking',
    'calendly',
    'schedule',
    'appointment',
    'tools',
    'Sell products',
  ],
})
