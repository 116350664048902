import { affiliateConfig } from '../affiliate/config'
import { appleMusicEmbedConfig } from '../apple-music-embed/config'
import { bannerConfig } from '../banner/config'
import { blogPostConfig } from '../blog-post/config'
import { bookingConfig } from '../booking/config'
import { calendarConfig } from '../calendar/config'
import { calendlyEmbedConfig } from '../calendly-embed/config'
import { contactConfig } from '../contact/config'
import { educationConfig } from '../education/config'
import { faqConfig } from '../faq/config'
import { figmaEmbedConfig } from '../figma-embed/config'
import { fileConfig } from '../file/config'
import { formConfig } from '../form/config'
import { galleryConfig } from '../gallery/config'
import { iconGroupConfig } from '../icon-group/config'
import { iframeConfig } from '../iframe/config'
import { imageComparisonConfig } from '../image-comparison/config'
import { instagramEmbedConfig } from '../instagram-embed/config'
import { languagesConfig } from '../languages/config'
import { linklistConfig } from '../link-list/config'
import { loomEmbedConfig } from '../loom-embed/config'
import { mapConfig } from '../map/config'
import { openingHoursConfig } from '../opening-hours/config'
import { pageBreakConfig } from '../page-break/config'
import { pinterestEmbedConfig } from '../pinterest-embed/config'
import { pollConfig } from '../poll/config'
import { portfolioConfig } from '../portfolio/config'
import { productConfig } from '../product/config'
import { projectConfig } from '../project/config'
import { qrConfig } from '../qr/config'
import { quotationConfig } from '../quotation/config'
import { ratingsConfig } from '../ratings/config'
import { serviceAreasConfig } from '../service-areas/config'
import { servicesConfig } from '../services/config'
import { skillsConfig } from '../skills/config'
import { socialFeedConfig } from '../social-feed/config'
import { socialIconGroupConfig } from '../social-icon-group/config'
import { soundcloudEmbedConfig } from '../soundcloud-embed/config'
import { spotifyConfig } from '../spotify/config'
import { subscribeConfig } from '../subscribe/config'
import { teamConfig } from '../team/config'
import { testimonialsConfig } from '../testimonials/config'
import { textConfig } from '../text/config'
import { tikTokEmbedConfig } from '../tiktok-embed/config'
import { twitchEmbedConfig } from '../twitch-embed/config'
import { videoConfig } from '../video/config'
import { weatherConfig } from '../weather/config'
import { websiteConfig } from '../website/config'
import { websiteEmbedConfig } from '../website-embed/config'
import { workHistoryConfig } from '../work-history/config'
import { xEmbedConfig } from '../x-embed/config'
import { BlockKind } from './types'

export const blockConfigSwitch = ({
  t,
  kind,
}: {
  t: any
  kind?: BlockKind | null
}) => {
  switch (kind) {
    case 'WEBSITE':
      return websiteConfig({ t })
    case 'IFRAME':
      return iframeConfig({ t })
    case 'OPENING_HOURS':
      return openingHoursConfig({ t })
    case 'RATINGS':
      return ratingsConfig({ t })
    case 'FILE':
      return fileConfig({ t })
    case 'SPOTIFY':
      return spotifyConfig({ t })
    case 'TEXT':
      return textConfig({ t })
    case 'GALLERY':
      return galleryConfig({ t })
    case 'MAP':
      return mapConfig({ t })
    case 'SUBSCRIBE':
      return subscribeConfig({ t })
    case 'CALENDAR':
      return calendarConfig({ t })
    case 'CONTACT':
      return contactConfig({ t })
    case 'BANNER':
      return bannerConfig({ t })
    case 'FAQ':
      return faqConfig({ t })
    case 'POLL':
      return pollConfig({ t })
    case 'FORM':
      return formConfig({ t })
    case 'PRODUCT':
      return productConfig({ t })
    case 'BOOKING':
      return bookingConfig({ t })
    case 'SOCIAL_ICON_GROUP':
      return socialIconGroupConfig({ t })
    case 'TESTIMONIALS':
      return testimonialsConfig({ t })
    case 'PAGE_BREAK':
      return pageBreakConfig({ t })
    case 'SERVICES':
      return servicesConfig({ t })
    case 'SERVICE_AREAS':
      return serviceAreasConfig({ t })
    case 'BLOG_POST':
      return blogPostConfig({ t })
    case 'SOCIAL_FEED':
      return socialFeedConfig({ t })
    case 'TEAM':
      return teamConfig({ t })
    case 'APPLE_MUSIC_EMBED':
      return appleMusicEmbedConfig({ t })
    case 'SOUNDCLOUD_EMBED':
      return soundcloudEmbedConfig({ t })
    case 'LOOM_EMBED':
      return loomEmbedConfig({ t })
    case 'PINTEREST_EMBED':
      return pinterestEmbedConfig({ t })
    case 'CALENDLY_EMBED':
      return calendlyEmbedConfig({ t })
    case 'FIGMA_EMBED':
      return figmaEmbedConfig({ t })
    case 'TIKTOK_EMBED':
      return tikTokEmbedConfig({ t })
    case 'INSTAGRAM_EMBED':
      return instagramEmbedConfig({ t })
    case 'TWITCH_EMBED':
      return twitchEmbedConfig({ t })
    case 'X_EMBED':
      return xEmbedConfig({ t })
    case 'ICON_GROUP':
      return iconGroupConfig({ t })
    case 'QUOTATION':
      return quotationConfig({ t })
    case 'PORTFOLIO':
      return portfolioConfig({ t })
    case 'SKILLS':
      return skillsConfig({ t })
    case 'LANGUAGES':
      return languagesConfig({ t })
    case 'WORK_HISTORY':
      return workHistoryConfig({ t })
    case 'EDUCATION':
      return educationConfig({ t })
    case 'IMAGE_COMPARISON':
      return imageComparisonConfig({ t })
    case 'WEATHER':
      return weatherConfig({ t })
    case 'QR':
      return qrConfig({ t })
    case 'PROJECT':
      return projectConfig({ t })
    case 'LINK_LIST':
      return linklistConfig({ t })
    case 'VIDEO':
      return videoConfig({ t })
    case 'WEBSITE_EMBED':
      return websiteEmbedConfig({ t })
    case 'AFFILIATE':
      return affiliateConfig({ t })
    default:
      return null
  }
}

export const blockCategories = [
  {
    searchTerm: 'Sell products',
  },
  {
    searchTerm: 'Grow and share',
  },
  {
    searchTerm: 'Tell them more',
  },
  {
    searchTerm: 'Help them find you',
  },
  {
    searchTerm: 'Express yourself',
  },
  {
    searchTerm: 'Embed the web',
  },
]

export const blockPreviewList = ({ t }: { t: any }) => [
  contactConfig({ t }),
  socialIconGroupConfig({ t }),
  galleryConfig({ t }),
  textConfig({ t }),
  websiteConfig({ t }),
  bookingConfig({ t }),
  spotifyConfig({ t }),
  portfolioConfig({ t }),
  socialFeedConfig({ t }),
  linklistConfig({ t }),
  mapConfig({ t }),
  openingHoursConfig({ t }),
  videoConfig({ t }),
  iconGroupConfig({ t }),
  productConfig({ t }),
  workHistoryConfig({ t }),
  projectConfig({ t }),
  educationConfig({ t }),
  formConfig({ t }),
  subscribeConfig({ t }),
  ratingsConfig({ t }),
  calendarConfig({ t }),
  faqConfig({ t }),
  skillsConfig({ t }),
  servicesConfig({ t }),
  serviceAreasConfig({ t }),
  fileConfig({ t }),
  bannerConfig({ t }),
  pollConfig({ t }),
  testimonialsConfig({ t }),
  pageBreakConfig({ t }),
  teamConfig({ t }),
  blogPostConfig({ t }),
  quotationConfig({ t }),
  weatherConfig({ t }),
  imageComparisonConfig({ t }),
  qrConfig({ t }),
  languagesConfig({ t }),
  appleMusicEmbedConfig({ t }),
  soundcloudEmbedConfig({ t }),
  loomEmbedConfig({ t }),
  pinterestEmbedConfig({ t }),
  calendlyEmbedConfig({ t }),
  figmaEmbedConfig({ t }),
  tikTokEmbedConfig({ t }),
  instagramEmbedConfig({ t }),
  twitchEmbedConfig({ t }),
  xEmbedConfig({ t }),
  iframeConfig({ t }),
  websiteEmbedConfig({ t }),
  affiliateConfig({ t }),
]
