import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const languagesContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    languages: z.array(
      z.object({
        language: z.string().optional(),
        proficiency: z.string().optional(),
      }),
    ),
  }),
)

export type LanguagesContentSchema = z.infer<typeof languagesContentSchema>

export const languagesConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'LANGUAGES',
  minW: 2,
  minH: 4,
  maxW: 4,
  maxH: 12,
  contentKey: 'languagesContent',
  schema: languagesContentSchema,
  name: t('languages'),
  description: t('include_your_language_abilities_on_your_page'),
  searchTags: [
    'languages',
    'english',
    'proficiency',
    'abilities',
    'linkedin',
    'personal',
    'grow and share',
  ],
})
