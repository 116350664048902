import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const formContentSchema = baseContentSchema.merge(
  z.object({
    formTemplateId: z.string().optional(),
  }),
)

export type FormContentSchema = z.infer<typeof formContentSchema>

export const formConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'FORM',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 12,
  contentKey: 'formContent',
  schema: formContentSchema,
  metaKey: 'formMeta',
  name: t('form'),
  description: t('capture_user_information_through_customisable_forms'),
  searchTags: [
    'form',
    'inquiry',
    'enquiry',
    'quotes',
    'contact us',
    'appraisals',
    'custom',
    'tools',
    'tell them more',
    'Sell products',
  ],
})
