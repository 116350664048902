import { LayoutItem } from '@fingertip/react-grid-layout'

import { blockConfigSwitch } from '@/components/blocks/shared/config-switch'
import { BlockKind, BlockType } from '@/components/blocks/shared/types'

export const getLayoutFromBlock = ({
  block,
  isResizable,
  t,
}: {
  block: BlockType
  isResizable?: boolean
  t: any
}) => {
  const content = block?.content?.content?.value
  const config = blockConfigSwitch({ kind: block.kind as BlockKind, t })

  const layout: LayoutItem = {
    i: block.id || '',
    w: content?.sm?.w !== undefined ? content.sm.w : 2,
    h: content?.sm?.h !== undefined ? content.sm.h : 4,
    x: content?.sm?.x !== undefined ? content.sm.x : 0,
    y: content?.sm?.y !== undefined ? content.sm.y : Infinity,
    minW: config?.minW || 1,
    minH: config?.minH || 2,
    maxW: config?.maxW || 1,
    maxH: config?.maxH || 1000,
    deg: 0,
    isResizable,
    static: !!block?.componentBlockId,
  }

  return layout
}
