import { makeAutoObservable } from 'mobx'

import { RootStore } from './root-store'

type Industry = {
  id: string
  name: string
}

export class OnboardingStore {
  rootStore: RootStore
  search: string = ''
  siteTemplateSlug: string | null = null
  industry: Industry | null | undefined = undefined

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true })
    this.rootStore = rootStore
  }

  setSearch(search: string) {
    this.search = search
  }

  setIndustry(industry: Industry | null | undefined) {
    this.industry = industry
  }

  setSiteTemplateSlug(siteTemplateSlug: string | null) {
    this.siteTemplateSlug = siteTemplateSlug
  }
}
