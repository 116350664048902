import { makeAutoObservable } from 'mobx'

import { RootStore } from './root-store'

export type ConfirmAttendanceStage = 'DETAILS' | 'FORM' | 'PAYMENT' | 'REDIRECT'

export class ConfirmAttendanceStore {
  rootStore: RootStore
  stage: ConfirmAttendanceStage = 'DETAILS'
  clientSecret: string | undefined
  paymentIntentId: string | undefined
  setupIntentId: string | undefined
  siteContactId: string | undefined
  attendanceId: string | undefined
  invoiceId: string | undefined
  receiptEmail: string | undefined
  amountToPayInCents: number | undefined
  formResponseId: string | undefined

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true })
    this.rootStore = rootStore
  }

  setStage(stage: ConfirmAttendanceStage) {
    this.stage = stage
  }

  setClientSecret(clientSecret: string | undefined) {
    this.clientSecret = clientSecret
  }

  setPaymentIntentId(paymentIntentId: string | undefined) {
    this.paymentIntentId = paymentIntentId
  }

  setSetupIntentId(setupIntentId: string | undefined) {
    this.setupIntentId = setupIntentId
  }

  setSiteContactId(siteContactId: string | undefined) {
    this.siteContactId = siteContactId
  }

  setAttendanceId(attendanceId: string | undefined) {
    this.attendanceId = attendanceId
  }

  setInvoiceId(invoiceId: string | undefined) {
    this.invoiceId = invoiceId
  }

  setReceiptEmail(receiptEmail: string | undefined) {
    this.receiptEmail = receiptEmail
  }

  setAmountToPayInCents(amountToPayInCents: number | undefined) {
    this.amountToPayInCents = amountToPayInCents
  }

  setFormResponseId(formResponseId: string | undefined) {
    this.formResponseId = formResponseId
  }

  reset() {
    this.stage = 'DETAILS'
    this.clientSecret = undefined
    this.paymentIntentId = undefined
    this.setupIntentId = undefined
    this.siteContactId = undefined
    this.attendanceId = undefined
    this.receiptEmail = undefined
    this.amountToPayInCents = undefined
    this.formResponseId = undefined
  }
}
