import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const linklistContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    links: z
      .array(
        z.object({
          media: z.any(),
          title: z.string().optional(),
          websiteUrl: z.string().optional(),
        }),
      )
      .optional(),
  }),
)

export type LinkListContentSchema = z.infer<typeof linklistContentSchema>

export const linklistConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'LINK_LIST',
  minW: 2,
  minH: 4,
  maxW: 4,
  maxH: 12,
  contentKey: 'linkListContent',
  schema: linklistContentSchema,
  name: t('link_list'),
  description: t('show_off_your_top_website_links'),
  searchTags: [
    'link lists',
    'linktree',
    'link in bio',
    'urls',
    'website',
    'http',
    'list',
  ],
})
