import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const fileContentSchema = baseContentSchema.merge(
  z.object({
    title: z.string().optional(),
    fileAssetId: z.string().optional(),
    fileAssetSrc: z.string().optional(),
    fileAssetBucket: z.string().optional(),
    description: z.string().optional(),
    buttonText: z.string().optional(),
    media: z.any(),
    collectEmail: z.boolean().optional(),
  }),
)

export type FileContentSchema = z.infer<typeof fileContentSchema>

export const fileConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'FILE',
  minW: 2,
  minH: 2,
  maxW: 4,
  maxH: 12,
  contentKey: 'fileContent',
  schema: fileContentSchema,
  name: t('download_0'),
  description: t('create_a_download_link_for_your_contacts'),
  examples: ['PDF', 'DOC', 'MP3'],
  searchTags: [
    'download',
    'doc',
    'mp3',
    'music',
    'pdf',
    'upload',
    'file',
    'tell them more',
  ],
})
