import { z } from 'zod'

import { baseContentSchema } from '../shared/schemas'
import { BlockConfig } from '../shared/types'

const testimonialsContentSchema = baseContentSchema.merge(
  z.object({
    testimonials: z.array(
      z.object({
        name: z.string().optional(),
        subtitle: z.string().optional(),
        text: z.string().optional(),
        media: z.any(),
      }),
    ),
  }),
)

export type TestimonialsContentSchema = z.infer<
  typeof testimonialsContentSchema
>

export const testimonialsConfig = ({ t }: { t: any }): BlockConfig => ({
  kind: 'TESTIMONIALS',
  minW: 3,
  minH: 4,
  maxW: 4,
  maxH: 12,
  contentKey: 'testimonialsContent',
  schema: testimonialsContentSchema,
  name: t('testimonials'),
  description: t('share_positive_feedback_from_satisfied_contacts_or_clients'),
  searchTags: [
    'recommendation',
    'references',
    'quotes',
    'testimonials',
    'tell them more',
  ],
})
