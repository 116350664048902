import { makeAutoObservable } from 'mobx'

import { RootStore } from './root-store'

type BookingCheckoutStage = 'CHECKOUT' | 'PAYMENT' | 'CONFIRMED'

export class BookingCheckoutStore {
  rootStore: RootStore
  stage: BookingCheckoutStage | undefined = 'CHECKOUT'
  clientSecret: string | undefined
  paymentIntentId: string | undefined
  setupIntentId: string | undefined
  siteContactId: string | undefined
  targetBookingId: string | undefined
  receiptEmail: string | undefined
  amountToPayInCents: number | undefined

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true })
    this.rootStore = rootStore
  }

  setStage(stage: BookingCheckoutStage | undefined) {
    this.stage = stage
  }

  setClientSecret(clientSecret: string | undefined) {
    this.clientSecret = clientSecret
  }

  setPaymentIntentId(paymentIntentId: string | undefined) {
    this.paymentIntentId = paymentIntentId
  }

  setSetupIntentId(setupIntentId: string | undefined) {
    this.setupIntentId = setupIntentId
  }

  setSiteContactId(siteContactId: string | undefined) {
    this.siteContactId = siteContactId
  }

  setTargetBookingId(targetBookingId: string | undefined) {
    this.targetBookingId = targetBookingId
  }

  setReceiptEmail(receiptEmail: string | undefined) {
    this.receiptEmail = receiptEmail
  }

  setAmountToPayInCents(amountToPayInCents: number | undefined) {
    this.amountToPayInCents = amountToPayInCents
  }

  reset() {
    this.stage = 'CHECKOUT'
    this.clientSecret = undefined
    this.paymentIntentId = undefined
    this.setupIntentId = undefined
    this.siteContactId = undefined
    this.targetBookingId = undefined
    this.receiptEmail = undefined
    this.amountToPayInCents = undefined
  }
}
